// src/pages/Admin/SchematicAdmin.js
import React, { useState, useEffect } from 'react';
import { collection, getDocs, updateDoc, doc, deleteDoc, query, orderBy } from 'firebase/firestore';
import { ref, deleteObject } from 'firebase/storage';
import { db, storage } from '../../services/firebase';
import { toast } from 'react-hot-toast';
import { useAuth } from '../../contexts/AuthContext';
import SchematicUploader from '../../components/Admin/SchematicUploader';
import { Edit, Trash, Eye, EyeOff, AlertTriangle, ArrowUp, ArrowDown, Check, X } from 'lucide-react';

const SCHEMATICS_COLLECTION = 'schematics';

const SchematicAdmin = () => {
  const { currentUser } = useAuth();
  const [schematics, setSchematics] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editingId, setEditingId] = useState(null);
  const [editName, setEditName] = useState('');

  // Fetch all schematics
  const fetchSchematics = async () => {
    setLoading(true);
    try {
      const schematicsRef = collection(db, SCHEMATICS_COLLECTION);
      const q = query(schematicsRef, orderBy('order'));
      const snapshot = await getDocs(q);
      
      setSchematics(
        snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }))
      );
    } catch (error) {
      console.error('Error fetching schematics:', error);
      toast.error('Failed to load schematics');
    } finally {
      setLoading(false);
    }
  };

  // Load schematics on component mount
  useEffect(() => {
    fetchSchematics();
  }, []);

  // Toggle active status
  const toggleActive = async (id, currentActive) => {
    try {
      await updateDoc(doc(db, SCHEMATICS_COLLECTION, id), {
        active: !currentActive,
        updatedAt: new Date()
      });
      
      // Update local state
      setSchematics(schematics.map(s => 
        s.id === id ? { ...s, active: !s.active } : s
      ));
      
      toast.success(`Schematic ${currentActive ? 'disabled' : 'enabled'}`);
    } catch (error) {
      console.error('Error toggling active status:', error);
      toast.error('Failed to update schematic');
    }
  };

  // Start editing a schematic name
  const startEdit = (schematic) => {
    setEditingId(schematic.id);
    setEditName(schematic.name);
  };

  // Save edited name
  const saveEdit = async () => {
    if (!editingId || !editName.trim()) {
      setEditingId(null);
      return;
    }
    
    try {
      await updateDoc(doc(db, SCHEMATICS_COLLECTION, editingId), {
        name: editName.trim(),
        updatedAt: new Date()
      });
      
      // Update local state
      setSchematics(schematics.map(s => 
        s.id === editingId ? { ...s, name: editName.trim() } : s
      ));
      
      toast.success('Schematic updated');
    } catch (error) {
      console.error('Error updating schematic:', error);
      toast.error('Failed to update schematic');
    } finally {
      setEditingId(null);
    }
  };

  // Delete a schematic
  const deleteSchematic = async (schematic) => {
    // Ask for confirmation
    if (!window.confirm(`Are you sure you want to delete "${schematic.name}"? This cannot be undone.`)) {
      return;
    }
    
    try {
      // 1. Delete Firestore document
      await deleteDoc(doc(db, SCHEMATICS_COLLECTION, schematic.id));
      
      // 2. Delete file from storage
      if (schematic.storagePath) {
        const storageRef = ref(storage, schematic.storagePath);
        await deleteObject(storageRef);
      }
      
      // 3. Update local state
      setSchematics(schematics.filter(s => s.id !== schematic.id));
      
      toast.success('Schematic deleted');
    } catch (error) {
      console.error('Error deleting schematic:', error);
      toast.error('Failed to delete schematic');
    }
  };

  // Change schematic order
  const changeOrder = async (id, direction) => {
    const index = schematics.findIndex(s => s.id === id);
    if (
      (direction === 'up' && index === 0) || 
      (direction === 'down' && index === schematics.length - 1)
    ) {
      return;
    }
    
    const targetIndex = direction === 'up' ? index - 1 : index + 1;
    const targetSchematic = schematics[targetIndex];
    const currentSchematic = schematics[index];
    
    try {
      // Swap orders in Firestore
      const batch = db.batch();
      
      batch.update(
        doc(db, SCHEMATICS_COLLECTION, currentSchematic.id),
        { order: targetSchematic.order, updatedAt: new Date() }
      );
      
      batch.update(
        doc(db, SCHEMATICS_COLLECTION, targetSchematic.id),
        { order: currentSchematic.order, updatedAt: new Date() }
      );
      
      await batch.commit();
      
      // Update local state by re-fetching
      await fetchSchematics();
      
    } catch (error) {
      console.error('Error changing schematic order:', error);
      toast.error('Failed to update order');
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-8">Schematic Management</h1>
      
      {/* Uploader Component */}
      <div className="mb-12 border rounded-lg p-6 bg-gray-50">
        <h2 className="text-xl font-semibold mb-4">Upload New Schematics</h2>
        <SchematicUploader onUploadComplete={fetchSchematics} />
      </div>
      
      {/* Schematic List */}
      <div>
        <h2 className="text-xl font-semibold mb-4">Manage Existing Schematics</h2>
        
        {loading ? (
          <div className="flex justify-center py-8">
            <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-blue-500"></div>
          </div>
        ) : schematics.length === 0 ? (
          <div className="text-center py-8 text-gray-500">
            No schematics found. Upload some using the form above.
          </div>
        ) : (
          <div className="bg-white shadow overflow-hidden sm:rounded-md">
            <ul className="divide-y divide-gray-200">
              {schematics.map((schematic) => (
                <li key={schematic.id}>
                  <div className="px-4 py-4 sm:px-6 flex items-center">
                    <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
                      <div className="flex items-center">
                        {/* Order Controls */}
                        <div className="flex flex-col mr-3">
                          <button
                            onClick={() => changeOrder(schematic.id, 'up')}
                            disabled={schematics.indexOf(schematic) === 0}
                            className={`p-1 ${schematics.indexOf(schematic) === 0 ? 'text-gray-300 cursor-not-allowed' : 'text-gray-500 hover:text-gray-700'}`}
                          >
                            <ArrowUp size={14} />
                          </button>
                          <button
                            onClick={() => changeOrder(schematic.id, 'down')}
                            disabled={schematics.indexOf(schematic) === schematics.length - 1}
                            className={`p-1 ${schematics.indexOf(schematic) === schematics.length - 1 ? 'text-gray-300 cursor-not-allowed' : 'text-gray-500 hover:text-gray-700'}`}
                          >
                            <ArrowDown size={14} />
                          </button>
                        </div>

                        {/* Thumbnail */}
                        <div className="flex-shrink-0 h-12 w-12 bg-gray-100 rounded-md overflow-hidden flex items-center justify-center mr-4">
                          {schematic.thumbnail ? (
                            <img 
                              src={schematic.thumbnail} 
                              alt={schematic.name}
                              className="h-12 w-12 object-contain"
                            />
                          ) : (
                            <span className="text-xs text-gray-500">No Image</span>
                          )}
                        </div>
                        
                        {/* Name */}
                        <div>
                          {editingId === schematic.id ? (
                            <div className="flex items-center">
                              <input 
                                type="text"
                                value={editName}
                                onChange={(e) => setEditName(e.target.value)}
                                className="border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                autoFocus
                              />
                              <button
                                onClick={saveEdit}
                                className="ml-2 p-1 text-green-600 hover:text-green-900"
                              >
                                <Check size={16} />
                              </button>
                              <button
                                onClick={() => setEditingId(null)}
                                className="p-1 text-red-600 hover:text-red-900"
                              >
                                <X size={16} />
                              </button>
                            </div>
                          ) : (
                            <p className="text-sm font-medium text-gray-900">
                              {schematic.name}
                              {!schematic.active && (
                                <span className="ml-2 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                                  Disabled
                                </span>
                              )}
                            </p>
                          )}
                          <p className="mt-1 text-xs text-gray-500 truncate">
                            {schematic.storagePath}
                          </p>
                        </div>
                      </div>
                    </div>
                    
                    {/* Action buttons */}
                    <div className="ml-4 flex items-center space-x-2">
                      <button
                        onClick={() => toggleActive(schematic.id, schematic.active)}
                        className={`p-2 rounded-full hover:bg-gray-100 
                          ${schematic.active ? 'text-green-600' : 'text-gray-400'}`}
                        title={schematic.active ? 'Disable' : 'Enable'}
                      >
                        {schematic.active ? <Eye size={16} /> : <EyeOff size={16} />}
                      </button>
                      <button
                        onClick={() => startEdit(schematic)}
                        className="p-2 rounded-full text-gray-500 hover:bg-gray-100"
                        title="Edit"
                      >
                        <Edit size={16} />
                      </button>
                      <button
                        onClick={() => deleteSchematic(schematic)}
                        className="p-2 rounded-full text-red-500 hover:bg-gray-100"
                        title="Delete"
                      >
                        <Trash size={16} />
                      </button>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default SchematicAdmin;