import React, { useState, useEffect, useRef } from 'react';
import SignaturePad from 'react-signature-canvas';
import { Calendar } from 'lucide-react';
import { useSearchParams } from 'react-router-dom';
import { doc, getDoc, collection, query, where, getDocs, updateDoc } from 'firebase/firestore';
import { db } from '../../../../services/firebase';

const ExternalQaQcApprovalForm = () => {
  const [searchParams] = useSearchParams();
  const [request, setRequest] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [submitMessage, setSubmitMessage] = useState('');
  const [formData, setFormData] = useState({
    name: '',
    approved: '',
    date: new Date().toISOString().split('T')[0],
    comments: '',
  });
  const [tokenDoc, setTokenDoc] = useState(null);
  const signaturePad = useRef();

  useEffect(() => {
    const fetchRequestData = async () => {
      try {
        const token = searchParams.get('token');
        if (!token) {
          throw new Error('No token provided');
        }

        const tokenQuery = query(
          collection(db, 'approvalTokens'),
          where('token', '==', token)
        );
        const tokenSnapshot = await getDocs(tokenQuery);

        if (tokenSnapshot.empty) {
          throw new Error('Invalid or expired token');
        }

        const foundTokenDoc = tokenSnapshot.docs[0];
        const tokenData = foundTokenDoc.data();

        if (tokenData.expiresAt.toDate() < new Date()) {
          throw new Error('Token has expired');
        }

        if (tokenData.used) {
          throw new Error('This approval link has already been used');
        }

        setTokenDoc(foundTokenDoc);

        const requestDoc = await getDoc(doc(db, 'EnergisationRequest', tokenData.requestId));
        
        if (!requestDoc.exists()) {
          throw new Error('Request not found');
        }

        setRequest({ id: requestDoc.id, ...requestDoc.data() });
      } catch (err) {
        console.error('Error fetching request:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchRequestData();
  }, [searchParams]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!request || !tokenDoc) {
      setError('Invalid request or token data');
      return;
    }

    setIsSubmitting(true);
    try {
      const signatureDataUrl = signaturePad.current?.isEmpty() 
        ? null 
        : signaturePad.current.toDataURL();

      if (!signatureDataUrl) {
        throw new Error('Signature is required');
      }
      const requestRef = doc(db, 'EnergisationRequest', request.id);
      await updateDoc(requestRef, {
        qaqcApproval: {
          approved: formData.approved === 'yes',
          comments: formData.comments,
          name: formData.name,
          date: formData.date,
          signature: signatureDataUrl,
          approvedAt: new Date().toISOString(),
        },
        status: formData.approved === 'yes' ? 'QaQcApproved' : 'QaQcRejected',
      });

      await updateDoc(doc(db, 'approvalTokens', tokenDoc.id), {
        used: true,
        usedAt: new Date(),
      });

      setError(null);
      setSubmitted(true);
      setSubmitMessage('QA/QC review submitted successfully');
    } catch (err) {
      console.error('Error submitting review:', err);
      setError(err.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  if (loading) {
    return (
      <div className="max-w-4xl mx-auto p-6">
        <div className="bg-gray-800 rounded-lg p-6 text-white text-center">
          Loading request data...
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="max-w-4xl mx-auto p-6">
        <div className="bg-red-600 rounded-lg p-6 text-white">
          <h3 className="text-lg font-semibold mb-2">Error</h3>
          <p>{error}</p>
        </div>
      </div>
    );
  }

  if (submitted) {
    return (
      <div className="max-w-4xl mx-auto p-6">
        <div className="bg-green-600 rounded-lg p-6 text-white">
          <h3 className="text-lg font-semibold mb-2">Success</h3>
          <p>{submitMessage}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-4xl mx-auto p-6 space-y-8">
      {/* Request Information Section */}
      <div className="bg-gray-800 rounded-lg p-6 space-y-4">
        <h2 className="text-xl font-semibold text-white mb-4">Request Information</h2>
        
        <div className="grid grid-cols-2 gap-4">
          <div>
            <h3 className="text-sm font-medium text-gray-400">Asset to Energise</h3>
            <div className="mt-1">
              <span className="inline-block bg-blue-900 text-white px-2 py-1 rounded mr-2 mb-2">
                {request?.assetToEnergise}
              </span>
            </div>
          </div>

          <div>
            <h3 className="text-sm font-medium text-gray-400">Source Asset Tag</h3>
            <p className="text-white inline-block bg-red-900 text-white px-2 py-1 rounded mr-2 mb-2">{request?.sourceAssetTag}</p>
          </div>

          <div>
            <h3 className="text-sm font-medium text-gray-400">Location</h3>
            <p className="text-white">{request?.location}</p>
          </div>

          <div>
            <h3 className="text-sm font-medium text-gray-400">Company</h3>
            <p className="text-white">{request?.company}</p>
          </div>

          <div>
            <h3 className="text-sm font-medium text-gray-400">Energisation Type</h3>
            <p className="text-white">{request?.energisationType}</p>
          </div>

          <div>
            <h3 className="text-sm font-medium text-gray-400">Requestor Name</h3>
            <p className="text-white">{request?.name}</p>
          </div>

          <div>
            <h3 className="text-sm font-medium text-gray-400">Requested Power On Date</h3>
            <p className="text-white">
              {request?.requestedPowerOnDate?.toDate().toLocaleDateString()}
            </p>
          </div>

          <div>
            <h3 className="text-sm font-medium text-gray-400">Voltage Range</h3>
            <p className="text-white">{request?.voltageRange}</p>
          </div>
        </div>
      </div>

      {/* Info Message */}
      <div className="bg-blue-900/30 border border-blue-500/50 rounded-lg p-4 flex items-start space-x-3">
        <svg className="w-6 h-6 text-blue-400 mt-0.5 flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
        <p className="text-sm text-blue-100">
        Having Reviewed the equipment Yellow Tag I can confirm the equipment / source detailed above can be energised and applied safety measures are appropriate. I authorise the energisation of the equipment detailed above
        </p>
      </div>

      {/* QA/QC Form */}
      <form onSubmit={handleSubmit} className="space-y-6 bg-gray-800 rounded-lg p-6">
        <h2 className="text-xl font-semibold text-white mb-4">QA/Cx Review Form</h2>

        <div className="space-y-4">
          <div className="space-y-2">
            <label className="block text-sm font-medium text-white">
              Approval Decision
            </label>
            <div className="flex space-x-4">
              {['Yes', 'No'].map((option) => (
                <label
                  key={option}
                  className={`
                    flex-1 py-2 px-4 rounded-lg text-center cursor-pointer transition-all
                    ${formData.approved === option.toLowerCase()
                      ? 'bg-blue-600 text-white ring-2 ring-blue-400'
                      : 'bg-gray-700 text-gray-300 hover:bg-gray-600'}
                  `}
                >
                  <input
                    type="radio"
                    name="approved"
                    value={option.toLowerCase()}
                    checked={formData.approved === option.toLowerCase()}
                    onChange={handleInputChange}
                    className="sr-only"
                    required
                  />
                  {option}
                </label>
              ))}
            </div>
          </div>

          <div className="space-y-2">
            <label htmlFor="comments" className="block text-sm font-medium text-white">
              Comments
            </label>
            <input
              id="comments"
              name="comments"
              type="text"
              value={formData.comments}
              onChange={handleInputChange}
              className="w-full px-3 py-2 bg-gray-700 text-white rounded-md focus:ring-2 focus:ring-blue-500"
              placeholder="Enter any comments or issues *"
              required
            />
          </div>

          <div className="space-y-2">
            <label htmlFor="name" className="block text-sm font-medium text-white">
              Name
            </label>
            <input
              id="name"
              name="name"
              type="text"
              value={formData.name}
              onChange={handleInputChange}
              className="w-full px-3 py-2 bg-gray-700 text-white rounded-md focus:ring-2 focus:ring-blue-500"
              placeholder="Enter your name *"
              required
            />
          </div>

          <div className="space-y-2">
            <label htmlFor="date" className="block text-sm font-medium text-white">
              Date
            </label>
            <div className="relative">
              <input
                id="date"
                name="date"
                type="date"
                value={formData.date}
                onChange={handleInputChange}
                className="w-full px-3 py-2 bg-gray-700 text-white rounded-md focus:ring-2 focus:ring-blue-500"
                required
              />
              <Calendar className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
            </div>
          </div>

          <div className="space-y-2">
            <label className="block text-sm font-medium text-white">
              Signature
            </label>
            <div className="border border-gray-600 rounded-lg bg-gray-700 p-2">
              <SignaturePad
                ref={signaturePad}
                canvasProps={{
                  className: "w-full h-32 bg-white rounded-md"
                }}
              />
            </div>
            <button
              type="button"
              onClick={() => signaturePad.current.clear()}
              className="mt-2 px-4 py-2 text-sm text-gray-300 bg-gray-700 rounded-lg hover:bg-gray-600 transition duration-200"
            >
              Clear Signature
            </button>
          </div>
        </div>

        <button
          type="submit"
          disabled={isSubmitting}
          className={`w-full py-3 px-4 rounded-md text-white font-medium transition-colors
            ${isSubmitting ? 'bg-gray-500 cursor-not-allowed' : 'bg-blue-600 hover:bg-blue-700'}`}
        >
          {isSubmitting ? 'Submitting...' : 'Submit Review'}
        </button>
      </form>
    </div>
  );
};

export default ExternalQaQcApprovalForm;
