import React, { useState } from 'react';
import { toast } from 'react-hot-toast';
import { Mail, ChevronLeft, ChevronRight } from 'lucide-react';
import { useRequests } from './RequestContext';
import ExpandedRowForms from './ExpandedRowForms/ExpandedRowForms';
import EmailManagerModal from './settings/EmailManagerModal';

const EnergisationRequestTable = () => {
  const { 
    requests, 
    loading, 
    error, 
    updateRequestStatus,
    currentPage,
    totalPages,
    hasMore,
    fetchNextPage,
    fetchPreviousPage,
    totalRequests,
    itemsPerPage
  } = useRequests();
  
  const [expandedRows, setExpandedRows] = useState({});
  const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);

  const toggleRowExpansion = (id) => {
    setExpandedRows(prev => ({
      ...prev,
      [id]: !prev[id]
    }));
  };

  const handleStatusUpdate = async (requestId, newStatus) => {
    try {
      await updateRequestStatus(requestId, newStatus);
      toast.success('Request status updated successfully');
      
      if (newStatus === 'ENERGISATION') {
        setExpandedRows(prev => ({
          ...prev,
          [requestId]: false
        }));
      }
    } catch (err) {
      toast.error('Failed to update request status');
      console.error('Error updating request status:', err);
    }
  };

  if (loading && requests.length === 0) {
    return (
      <div className="flex justify-center items-center p-8">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-red-500 text-center p-4">
        {error}
      </div>
    );
  }

  const getStatusColor = (status) => {
    switch (status.toUpperCase()) {
      case 'PENDING':
        return 'bg-yellow-600';
      case 'GCAPPROVED':
        return 'bg-blue-600';
      case 'QAQCAPPROVED':
          return 'bg-green-600';
      case 'PENDINGGCAPPROVAL':
        return 'bg-orange-600';
      case 'SAP':
        return 'bg-purple-600';
      case 'ENERGISATION':
        return 'bg-green-600';
      default:
        return 'bg-gray-600';
    }
  };

  // Pagination display calculation
  const startItem = (currentPage - 1) * itemsPerPage + 1;
  const endItem = Math.min(currentPage * itemsPerPage, totalRequests);

  return (
    <div className="bg-gray-900 rounded-xl shadow-2xl max-w-7xl mx-auto border border-gray-800">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-3xl font-bold text-white bg-gradient-to-r from-blue-500 to-purple-500 bg-clip-text text-transparent">
          Energisation Requests
        </h2>
        <button
          onClick={() => setIsEmailModalOpen(true)}
          className="p-2 text-gray-400 hover:text-white hover:bg-gray-700 rounded-lg transition-colors"
          title="Manage Email Lists"
        >
          <Mail size={20} />
        </button>
      </div>
      
      {requests.length === 0 ? (
        <div className="text-white text-center py-8">
          No pending requests found
        </div>
      ) : (
        <>
          <div className="overflow-x-auto">
            <table className="w-full text-sm text-left text-gray-300 border-collapse">
              <thead className="text-xs uppercase bg-gray-800/60 text-gray-300 border-b border-gray-700">
                <tr className="backdrop-blur-sm">
                  <th scope="col" className="px-6 py-3">Name</th>
                  <th scope="col" className="px-6 py-3">Email</th>
                  <th scope="col" className="px-6 py-3">Company</th>
                  <th scope="col" className="px-6 py-3">Asset to Energise</th>
                  <th scope="col" className="px-6 py-3">Voltage Range</th>
                  <th scope="col" className="px-6 py-3">Location</th>
                  <th scope="col" className="px-6 py-3">Requested Power On Date</th>
                  <th scope="col" className="px-6 py-3">Energisation Type</th>
                  <th scope="col" className="px-6 py-3">Source Asset Tag</th>
                  <th scope="col" className="px-6 py-3">Approved Status</th>
                </tr>
              </thead>
              <tbody>
                {requests.map((request) => (
                  <React.Fragment key={request.id}>
                    <tr 
                      className="border-b border-gray-700 bg-gray-800/40 hover:bg-gray-700/50 cursor-pointer transition-all duration-200"
                      onClick={() => toggleRowExpansion(request.id)}
                    >
                      <td className="px-6 py-4">{request.name || 'N/A'}</td>
                      <td className="px-6 py-4">{request.email || 'N/A'}</td>
                      <td className="px-6 py-4">{request.company || 'N/A'}</td>
                      <td className="px-6 py-4">{request.assetToEnergise || 'N/A'}</td>
                      <td className="px-6 py-4">{request.voltageRange || 'N/A'}</td>
                      <td className="px-6 py-4">{request.location || 'N/A'}</td>
                      <td className="px-6 py-4">{request.requestedPowerOnDate || 'N/A'}</td>
                      <td className="px-6 py-4">{request.energisationType || 'N/A'}</td>
                      <td className="px-6 py-4">{request.sourceAssetTag || 'N/A'}</td>
                      <td className="px-6 py-4">
                        <span className={`px-3 py-1.5 rounded-full text-xs font-medium ${getStatusColor(request.status || 'N/A')} shadow-lg transition-all duration-200 hover:scale-105`}>
                          {(request.status || 'N/A').toUpperCase()}
                        </span>
                      </td>
                    </tr>
                    {expandedRows[request.id] && (
                      <tr>
                        <td colSpan="10" className="px-6 py-4 bg-gray-900">
                          <ExpandedRowForms 
                            request={request} 
                            updateRequestStatus={handleStatusUpdate}
                          />
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </div>
          
          {/* Pagination Controls */}
          <div className="flex justify-between items-center pt-4 pb-2 px-6 border-t border-gray-700 mt-4">
            <div className="text-sm text-gray-400">
              {loading && requests.length > 0 ? (
                <span>Loading...</span>
              ) : (
                <span>
                  Showing {startItem} to {endItem} of {totalRequests} requests
                </span>
              )}
            </div>
            <div className="flex space-x-2">
              <button
                onClick={fetchPreviousPage}
                disabled={currentPage === 1 || loading}
                className={`p-2 rounded-md flex items-center ${
                  currentPage === 1 || loading
                    ? 'bg-gray-700 text-gray-500 cursor-not-allowed'
                    : 'bg-gray-700 text-white hover:bg-gray-600'
                }`}
              >
                <ChevronLeft size={16} />
                <span className="ml-1">Previous</span>
              </button>
              <div className="flex items-center px-4 py-2 bg-gray-800 rounded-md">
                <span className="text-white">Page {currentPage}</span>
                {totalPages > 0 && <span className="text-gray-400"> of {totalPages}</span>}
              </div>
              <button
                onClick={fetchNextPage}
                disabled={!hasMore || loading}
                className={`p-2 rounded-md flex items-center ${
                  !hasMore || loading
                    ? 'bg-gray-700 text-gray-500 cursor-not-allowed'
                    : 'bg-gray-700 text-white hover:bg-gray-600'
                }`}
              >
                <span className="mr-1">Next</span>
                <ChevronRight size={16} />
              </button>
            </div>
          </div>
        </>
      )}

      <EmailManagerModal
        isOpen={isEmailModalOpen}
        onClose={() => setIsEmailModalOpen(false)}
        userRole="admin" // Replace with your actual user role logic
      />
    </div>
  );
};

export default EnergisationRequestTable;