// src/components/ETracker/ExternalEtracker.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import ExternalTabbedContainer from './ExternalTabbedContainer';
import logo from '../assets/logo.png'; // Update this path to match your logo location
import { LogOut } from 'lucide-react';

const ExternalEtracker = () => {
  const navigate = useNavigate();
  
  const handleLogout = () => {
    localStorage.removeItem('energy_tracker_access');
    navigate('/energisation-tracker-wxt/access');
  };

  return (
    <div className="w-full">
      <div className="bg-gray-900 p-8 mb-6 rounded-lg shadow-lg text-center relative">
        <button 
          onClick={handleLogout}
          className="absolute right-4 top-4 text-gray-400 hover:text-white flex items-center gap-2 px-3 py-2 rounded-md hover:bg-gray-800 transition-colors"
        >
          <LogOut size={16} />
          <span>Logout</span>
        </button>
        
        <div className="flex justify-center mb-4">
          <img
            src={logo}
            alt="Company Logo"
            className="h-16 w-auto"
          />
        </div>
        <h2 className="text-3xl font-bold text-white">Energisation Tracker</h2>
        <p className="text-gray-300 mt-2">View and track your energisation requests</p>
      </div>
      
      <ExternalTabbedContainer />
    </div>
  );
};

export default ExternalEtracker;