import { useState, useEffect } from 'react';
import { UserService } from './UserService';

export const useUserDetails = (permitData) => {
  const [preparedByUser, setPreparedByUser] = useState(null);
  const [issuerData, setIssuerData] = useState(null);
  const [closedByUser, setClosedByUser] = useState(null);
  const [controlEngineerData, setControlEngineerData] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        // Fetch prepared by user data
        if (permitData?.preparedBy?.userId) {
          const preparedByData = await UserService.getUserById(permitData.preparedBy.userId);
          setPreparedByUser(preparedByData);
        }

        // Fetch issuer data
        if (permitData?.issuedDetails?.issuedBy?.userId) {
          const issuerUserData = await UserService.getUserById(permitData.issuedDetails.issuedBy.userId);
          setIssuerData(issuerUserData);
        }

        // Fetch closed by user data
        if (permitData?.closeDetails?.closedBy?.userId) {
          const closedByData = await UserService.getUserById(permitData.closeDetails.closedBy.userId);
          setClosedByUser(closedByData);
        }
        
        // Fetch control engineer data
        if (permitData?.consentDetails?.controlEngineerId) {
          const engineerData = await UserService.getUserById(permitData.consentDetails.controlEngineerId);
          setControlEngineerData(engineerData);
        }
      } catch (error) {
        console.error('Error fetching user details:', error);
      }
    };

    fetchUserData();
  }, [permitData]);

  return { preparedByUser, issuerData, closedByUser, controlEngineerData };
};