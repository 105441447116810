// src/components/ETracker/RequireAccess.js
import React, { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

const ACCESS_TOKEN_KEY = 'energy_tracker_access';

const RequireAccess = ({ children }) => {
  const [isAuthorized, setIsAuthorized] = useState(null);
  const location = useLocation();

  useEffect(() => {
    // Check if the user has a valid access token
    const checkAuthorization = () => {
      const storedToken = localStorage.getItem(ACCESS_TOKEN_KEY);
      
      if (!storedToken) {
        setIsAuthorized(false);
        return;
      }
      
      try {
        const tokenData = JSON.parse(storedToken);
        const isExpired = new Date().getTime() > tokenData.expiresAt;
        
        if (isExpired) {
          // Token expired, remove it
          localStorage.removeItem(ACCESS_TOKEN_KEY);
          setIsAuthorized(false);
        } else {
          setIsAuthorized(true);
        }
      } catch (err) {
        // Invalid token format, remove it
        localStorage.removeItem(ACCESS_TOKEN_KEY);
        setIsAuthorized(false);
      }
    };
    
    checkAuthorization();
  }, []);

  // Show nothing while checking authorization
  if (isAuthorized === null) {
    return (
      <div className="min-h-screen bg-gray-900 flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  // Redirect to access code page if not authorized
  if (!isAuthorized) {
    return (
      <Navigate
        to={`/energisation-tracker-wxt/access?from=${encodeURIComponent(location.pathname)}`}
        replace
      />
    );
  }

  // Render children if authorized
  return children;
};

export default RequireAccess;