import { db } from './firebase';
import { 
  collection, 
  getDocs, 
  addDoc, 
  deleteDoc, 
  query, 
  where,
  doc
} from 'firebase/firestore';

const EMAIL_LISTS = {
  GC: 'gcEmailList',
  QAQC: 'qaqcEmailList'
};

export const emailListService = {
  // Fetch all emails for a specific type (GC or QAQC)
  async getEmails(type) {
    try {
      const emailsRef = collection(db, EMAIL_LISTS[type]);
      const snapshot = await getDocs(emailsRef);
      return snapshot.docs.map(doc => ({
        id: doc.id,
        email: doc.data().email
      }));
    } catch (error) {
      console.error('Error fetching emails:', error);
      throw error;
    }
  },

  // Add a new email to the list
  async addEmail(type, email) {
    try {
      const emailsRef = collection(db, EMAIL_LISTS[type]);
      
      // Check if email already exists
      const q = query(emailsRef, where("email", "==", email));
      const querySnapshot = await getDocs(q);
      
      if (!querySnapshot.empty) {
        throw new Error('Email already exists in the list');
      }

      await addDoc(emailsRef, { 
        email,
        createdAt: new Date()
      });
    } catch (error) {
      console.error('Error adding email:', error);
      throw error;
    }
  },

  // Remove an email from the list
  async removeEmail(type, emailId) {
    try {
      const emailRef = doc(db, EMAIL_LISTS[type], emailId);
      await deleteDoc(emailRef);
    } catch (error) {
      console.error('Error removing email:', error);
      throw error;
    }
  }
};