import React, { useState } from 'react';
import { useAuth } from '../../../contexts/AuthContext';
import useCurrentUser from '../PermitsStatus/hooks/useCurrentUser';

const ControlEngineerConsentModal = ({ isOpen, onClose, onConsent, permitData }) => {
  const { currentUser } = useAuth();
  const { userData } = useCurrentUser();
  const [keysafeNumber, setKeysafeNumber] = useState('');

  const handleInputChange = (e) => {
    setKeysafeNumber(e.target.value);
  };

  const handleSubmit = () => {
    if (!keysafeNumber) {
      alert('Please enter a keysafe number');
      return;
    }

    const updatedPermitData = {
      id: permitData.id,
      consentDetails: {
        controlEngineerId: currentUser?.uid,
        keysafeNumber: keysafeNumber,
        timestamp: new Date().toISOString()
      }
    };

    onConsent(updatedPermitData);
  };

  if (!isOpen) return null;

  return (
    <div 
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
      onClick={(e) => e.target === e.currentTarget && onClose()}
    >
      <div className="bg-gray-900 rounded-lg shadow-xl w-full max-w-md border border-gray-700">
        {/* Header */}
        <div className="flex justify-between items-center p-4 border-b border-gray-700">
          <h2 className="text-xl font-semibold text-gray-100">
            Control Engineer Consent
          </h2>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-200 transition-colors"
          >
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>

        {/* Content */}
        <div className="p-4 space-y-4">
          {/* Control Engineer Section */}
          <div className="space-y-4">
            <h3 className="text-lg font-medium text-gray-100">Control Engineer Details</h3>
            <div className="space-y-2">
              <div className="text-sm text-gray-200 bg-gray-800 p-3 rounded-md border border-gray-700">
                <p>Name: {userData?.name || 'Loading...'}</p>
                <p>Contact: {userData?.phoneNumber || 'N/A'}</p>
              </div>
              {userData?.signature && (
                <div className="bg-gray-800 p-3 rounded-md border border-gray-700">
                  <img 
                    src={userData.signature} 
                    alt="Signature" 
                    className="h-12 object-contain"
                  />
                </div>
              )}
            </div>
          </div>

          {/* Keysafe Number Section */}
          <div className="space-y-4">
            <h3 className="text-lg font-medium text-gray-100">Keysafe Number</h3>
            <div className="space-y-2">
              <input
                type="text"
                name="keysafeNumber"
                value={keysafeNumber}
                onChange={handleInputChange}
                placeholder="Enter keysafe number"
                className="mt-1 p-2 block w-full rounded-md border-gray-600 bg-gray-800 text-gray-200 shadow-sm focus:border-blue-500 focus:ring-blue-500 focus:ring-opacity-50 focus:ring-2"
              />
            </div>
          </div>
        </div>

        {/* Footer */}
        <div className="flex justify-end gap-2 p-4 border-t border-gray-700">
          <button
            onClick={onClose}
            className="px-4 py-2 text-sm font-medium text-gray-200 bg-gray-700 border border-gray-600 rounded-md hover:bg-gray-600 transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-900 focus:ring-blue-500"
          >
            Cancel
          </button>
          <button
            onClick={handleSubmit}
            className="px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md hover:bg-blue-500 transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-900 focus:ring-blue-500"
          >
            Submit Consent
          </button>
        </div>
      </div>
    </div>
  );
};

export default ControlEngineerConsentModal;