import React, { useState, useEffect } from 'react';
import { Plus, Trash2 } from 'lucide-react';
import { toast } from 'react-hot-toast';
import { emailListService } from '../../../services/emailListService';

const EmailListManager = ({ type, userRole }) => {
  const [emails, setEmails] = useState([]);
  const [newEmail, setNewEmail] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  // Check if user has permission to manage emails
  const canManageEmails = ['admin', 'sap'].includes(userRole?.toLowerCase());

  useEffect(() => {
    loadEmails();
  }, [type]);

  const loadEmails = async () => {
    try {
      const emailList = await emailListService.getEmails(type);
      setEmails(emailList);
    } catch (error) {
      toast.error('Failed to load email list');
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddEmail = async (e) => {
    e.preventDefault();
    if (!newEmail) return;

    try {
      await emailListService.addEmail(type, newEmail);
      toast.success('Email added successfully');
      setNewEmail('');
      loadEmails();
    } catch (error) {
      toast.error(error.message || 'Failed to add email');
    }
  };

  const handleRemoveEmail = async (emailId) => {
    try {
      await emailListService.removeEmail(type, emailId);
      toast.success('Email removed successfully');
      loadEmails();
    } catch (error) {
      toast.error('Failed to remove email');
    }
  };

  if (!canManageEmails) {
    return null;
  }

  return (
    <div className="p-4 bg-gray-700 rounded-lg space-y-4">
      <h3 className="text-lg font-medium text-white">
        Manage {type} Email List
      </h3>
      
      {/* Add new email form */}
      <form onSubmit={handleAddEmail} className="flex gap-2">
        <input
          type="email"
          value={newEmail}
          onChange={(e) => setNewEmail(e.target.value)}
          placeholder="Enter email address"
          className="flex-1 px-3 py-2 bg-gray-600 rounded-md text-white placeholder-gray-400"
          required
        />
        <button
          type="submit"
          className="flex items-center gap-2 px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
        >
          <Plus size={16} />
          Add
        </button>
      </form>

      {/* Email list */}
      <div className="space-y-2">
        {isLoading ? (
          <div className="text-gray-400">Loading...</div>
        ) : emails.length === 0 ? (
          <div className="text-gray-400">No emails in list</div>
        ) : (
          emails.map((email) => (
            <div
              key={email.id}
              className="flex items-center justify-between p-2 bg-gray-600 rounded-md"
            >
              <span className="text-white">{email.email}</span>
              <button
                onClick={() => handleRemoveEmail(email.id)}
                className="text-gray-400 hover:text-red-400"
              >
                <Trash2 size={16} />
              </button>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default EmailListManager;