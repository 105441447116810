// src/components/ETracker/ExternalRequestsTable.js
import React, { useState, useEffect } from 'react';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../../services/firebase';
import { ChevronLeft, ChevronRight } from 'lucide-react';

const ExternalRequestsTable = () => {
  const [requests, setRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 15;

  useEffect(() => {
    const fetchRequests = async () => {
      setLoading(true);
      try {
        const q = query(
          collection(db, 'EnergisationRequest'),
          where('status', '!=', 'ENERGISATION')
        );
        const querySnapshot = await getDocs(q);
        const fetchedRequests = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          requestedPowerOnDate: doc.data().requestedPowerOnDate?.toDate().toISOString().split('T')[0]
        }));
        setRequests(fetchedRequests);
      } catch (err) {
        console.error('Error fetching in-progress requests:', err);
        setError('Failed to load requests. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchRequests();
  }, []);

  const getStatusColor = (status) => {
    switch (status.toUpperCase()) {
      case 'PENDING':
        return 'bg-yellow-600';
      case 'GCAPPROVED':
        return 'bg-blue-600';
      case 'QAQCAPPROVED':
        return 'bg-green-600';
      case 'PENDINGGCAPPROVAL':
        return 'bg-orange-600';
      case 'SAP':
        return 'bg-purple-600';
      default:
        return 'bg-gray-600';
    }
  };

  // Calculate pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = requests.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(requests.length / itemsPerPage);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const nextPage = () => setCurrentPage(prev => Math.min(prev + 1, totalPages));
  const prevPage = () => setCurrentPage(prev => Math.max(prev - 1, 1));

  if (loading) {
    return (
      <div className="flex justify-center items-center p-8">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-red-500 text-center p-4">
        {error}
      </div>
    );
  }

  return (
    <div className="bg-gray-900 shadow-2xl rounded-lg w-full">
      <div className="p-6">
        <h2 className="text-2xl font-bold mb-6 text-white">In Progress Requests</h2>
        
        {requests.length === 0 ? (
          <div className="text-white text-center py-8">
            No pending requests found
          </div>
        ) : (
          <>
            <div className="overflow-x-auto">
              <table className="w-full text-sm text-left text-gray-300 border-collapse">
                <thead className="text-xs uppercase bg-gray-800/60 text-gray-300 border-b border-gray-700">
                  <tr className="backdrop-blur-sm">
                    <th scope="col" className="px-6 py-3">Name</th>
                    <th scope="col" className="px-6 py-3">Company</th>
                    <th scope="col" className="px-6 py-3">Asset to Energise</th>
                    <th scope="col" className="px-6 py-3">Voltage Range</th>
                    <th scope="col" className="px-6 py-3">Location</th>
                    <th scope="col" className="px-6 py-3">Requested Power On Date</th>
                    <th scope="col" className="px-6 py-3">Energisation Type</th>
                    <th scope="col" className="px-6 py-3">Source Asset Tag</th>
                    <th scope="col" className="px-6 py-3">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {currentItems.map((request) => (
                    <tr
                      key={request.id}
                      className="border-b border-gray-700 bg-gray-800/40 hover:bg-gray-700/50 transition-all duration-200"
                    >
                      <td className="px-6 py-4">{request.name || 'N/A'}</td>
                      <td className="px-6 py-4">{request.company || 'N/A'}</td>
                      <td className="px-6 py-4">{request.assetToEnergise || 'N/A'}</td>
                      <td className="px-6 py-4">{request.voltageRange || 'N/A'}</td>
                      <td className="px-6 py-4">{request.location || 'N/A'}</td>
                      <td className="px-6 py-4">{request.requestedPowerOnDate || 'N/A'}</td>
                      <td className="px-6 py-4">{request.energisationType || 'N/A'}</td>
                      <td className="px-6 py-4">{request.sourceAssetTag || 'N/A'}</td>
                      <td className="px-6 py-4">
                        <span className={`px-3 py-1.5 rounded-full text-xs font-medium ${getStatusColor(request.status || 'N/A')} shadow-lg`}>
                          {(request.status || 'N/A').toUpperCase()}
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            
            {/* Pagination */}
            {totalPages > 1 && (
              <div className="flex items-center justify-between mt-6 px-4">
                <div className="text-sm text-gray-400">
                  Showing {indexOfFirstItem + 1}-{Math.min(indexOfLastItem, requests.length)} of {requests.length} requests
                </div>
                <div className="flex items-center space-x-2">
                  <button
                    onClick={prevPage}
                    disabled={currentPage === 1}
                    className="p-2 rounded-md bg-gray-700 text-white disabled:opacity-50 disabled:cursor-not-allowed"
                  >
                    <ChevronLeft size={18} />
                  </button>
                  
                  <div className="flex items-center space-x-1">
                    {Array.from({ length: totalPages }, (_, i) => i + 1).map(number => (
                      <button
                        key={number}
                        onClick={() => paginate(number)}
                        className={`w-8 h-8 rounded-md flex items-center justify-center ${
                          currentPage === number 
                            ? 'bg-blue-600 text-white' 
                            : 'bg-gray-700 text-gray-300 hover:bg-gray-600'
                        }`}
                      >
                        {number}
                      </button>
                    ))}
                  </div>
                  
                  <button
                    onClick={nextPage}
                    disabled={currentPage === totalPages}
                    className="p-2 rounded-md bg-gray-700 text-white disabled:opacity-50 disabled:cursor-not-allowed"
                  >
                    <ChevronRight size={18} />
                  </button>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ExternalRequestsTable;