// src/components/Admin/SchematicUploader.js
import React, { useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { collection, addDoc, getDocs, query, where } from 'firebase/firestore';
import { db, storage } from '../../services/firebase';
import { toast } from 'react-hot-toast';
import { Upload, X, Check, AlertTriangle } from 'lucide-react';
import { useAuth } from '../../contexts/AuthContext';

const SCHEMATICS_COLLECTION = 'schematics';
const STORAGE_PATH = 'schematics';

const SchematicUploader = ({ onUploadComplete }) => {
  const { currentUser } = useAuth();
  const [files, setFiles] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState({});
  const [uploadResults, setUploadResults] = useState([]);

  // Handle file drop/selection
  const onDrop = useCallback(acceptedFiles => {
    // Filter for only image files
    const imageFiles = acceptedFiles.filter(file => 
      file.type.startsWith('image/')
    );
    
    // Add new properties to the files
    const filesWithProps = imageFiles.map(file => ({
      file,
      id: `${Date.now()}-${Math.random().toString(36).substr(2, 9)}`,
      name: file.name.replace(/\.[^/.]+$/, ""), // Remove extension for display name
      status: 'pending',
      progress: 0
    }));
    
    setFiles(prev => [...prev, ...filesWithProps]);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ 
    onDrop,
    accept: {
      'image/*': ['.jpeg', '.jpg', '.png', '.gif']
    }
  });

  // Handle file remove
  const removeFile = (id) => {
    setFiles(files.filter(file => file.id !== id));
  };

  // Update file name
  const updateFileName = (id, newName) => {
    setFiles(files.map(file => 
      file.id === id ? { ...file, name: newName } : file
    ));
  };

  const uploadFile = async (fileItem) => {
    const { file, name, id } = fileItem;
    const sanitizedName = name.replace(/[^a-z0-9-_]/gi, '-').toLowerCase();
    const fileExtension = file.name.split('.').pop();
    const fullPath = `${STORAGE_PATH}/${sanitizedName}.${fileExtension}`;
    
    // Check if schematic with this name already exists
    const schematicsRef = collection(db, SCHEMATICS_COLLECTION);
    const q = query(schematicsRef, where("name", "==", name));
    const querySnapshot = await getDocs(q);
    
    if (!querySnapshot.empty) {
      return {
        id,
        success: false,
        error: 'Schematic with this name already exists'
      };
    }
    
    try {
      // Calculate image dimensions before upload
      const dimensions = await new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => {
          // Get dimensions
          const width = img.width;
          const height = img.height;
          URL.revokeObjectURL(img.src); // Clean up
          resolve({ width, height });
        };
        img.onerror = () => {
          URL.revokeObjectURL(img.src); // Clean up
          reject(new Error('Failed to load image for dimension calculation'));
        };
        img.src = URL.createObjectURL(file);
      });
      
      // 1. Upload file to Firebase Storage
      const storageRef = ref(storage, fullPath);
      const uploadTask = uploadBytesResumable(storageRef, file);
      
      // Return a promise that resolves when upload is complete
      return new Promise((resolve, reject) => {
        uploadTask.on(
          'state_changed',
          (snapshot) => {
            // Update progress
            const progress = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
            setUploadProgress(prev => ({ ...prev, [id]: progress }));
          },
          (error) => {
            // Handle unsuccessful uploads
            console.error("Upload failed:", error);
            resolve({
              id,
              success: false,
              error: error.message
            });
          },
          async () => {
            try {
              // 2. Create Firestore entry with dimensions
              const docData = {
                name: name,
                storagePath: fullPath,
                dimensions: dimensions, // Store dimensions in Firestore
                order: Date.now(), // Use timestamp for default ordering
                active: true,
                createdAt: new Date(),
                updatedAt: new Date(),
                createdBy: currentUser?.uid || 'unknown'
              };
              
              const docRef = await addDoc(collection(db, SCHEMATICS_COLLECTION), docData);
              
              resolve({
                id,
                success: true,
                schematicId: docRef.id,
                name: name,
                storagePath: fullPath,
                dimensions: dimensions
              });
            } catch (error) {
              console.error("Firestore error:", error);
              resolve({
                id,
                success: false,
                error: error.message
              });
            }
          }
        );
      });
    } catch (error) {
      console.error("Error starting upload:", error);
      return {
        id,
        success: false,
        error: error.message
      };
    }
  };

  // Start uploading all files
  const startUpload = async () => {
    if (files.length === 0) return;
    
    setUploading(true);
    setUploadResults([]);
    const loadingToast = toast.loading(`Uploading ${files.length} schematics...`);
    
    try {
      // Upload files in sequence to avoid overloading
      const results = [];
      for (const file of files) {
        const result = await uploadFile(file);
        results.push(result);
        setUploadResults(prev => [...prev, result]);
      }
      
      // Show success/error toast
      const successCount = results.filter(r => r.success).length;
      if (successCount === files.length) {
        toast.success(`Successfully uploaded ${successCount} schematics`);
      } else {
        toast.error(`${successCount}/${files.length} schematics uploaded successfully`);
      }
      
      // If all succeeded, clear the files list
      if (successCount === files.length) {
        setFiles([]);
      } else {
        // Remove successful uploads from the list
        const successIds = results.filter(r => r.success).map(r => r.id);
        setFiles(files.filter(f => !successIds.includes(f.id)));
      }
      
      // Call the onUploadComplete callback if provided
      if (onUploadComplete && typeof onUploadComplete === 'function') {
        onUploadComplete();
      }
    } catch (error) {
      console.error("Upload error:", error);
      toast.error("Error uploading schematics");
    } finally {
      toast.dismiss(loadingToast);
      setUploading(false);
    }
  };

  return (
    <div className="w-full max-w-5xl mx-auto">
      {/* Dropzone area */}
      <div 
        {...getRootProps()} 
        className={`border-2 border-dashed p-10 rounded-lg text-center cursor-pointer transition duration-200 
          ${isDragActive 
            ? 'border-blue-500 bg-blue-50' 
            : 'border-gray-300 hover:border-blue-400'}`}
      >
        <input {...getInputProps()} />
        <Upload className="mx-auto h-12 w-12 text-gray-400 mb-3" />
        <p className="text-lg text-gray-700">
          {isDragActive 
            ? "Drop the schematic files here..." 
            : "Drag & drop schematic images here, or click to select files"}
        </p>
        <p className="text-sm text-gray-500 mt-2">
          Supported formats: JPG, PNG, GIF
        </p>
      </div>
      
      {/* File list */}
      {files.length > 0 && (
        <div className="mt-8">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-lg font-semibold">Schematic Files ({files.length})</h2>
            <button
              onClick={startUpload}
              disabled={uploading || files.length === 0}
              className={`px-4 py-2 rounded-md flex items-center gap-2 
                ${uploading ? 'bg-gray-300 cursor-not-allowed' : 'bg-blue-500 text-white hover:bg-blue-600'}`}
            >
              <Upload size={16} />
              {uploading ? 'Uploading...' : 'Upload All'}
            </button>
          </div>
          
          <div className="space-y-3">
            {files.map((fileItem) => (
              <div 
                key={fileItem.id}
                className="flex items-center gap-4 p-3 border rounded-md bg-white"
              >
                <div className="w-12 h-12 bg-gray-100 rounded-md flex items-center justify-center overflow-hidden">
                  <img 
                    src={URL.createObjectURL(fileItem.file)} 
                    alt={fileItem.name}
                    className="w-full h-full object-contain"
                  />
                </div>
                
                <div className="flex-1">
                  <input
                    type="text"
                    value={fileItem.name}
                    onChange={(e) => updateFileName(fileItem.id, e.target.value)}
                    disabled={uploading}
                    className="w-full p-2 border rounded"
                    placeholder="Schematic name"
                  />
                  
                  {/* Show progress bar when uploading */}
                  {uploading && uploadProgress[fileItem.id] !== undefined && (
                    <div className="w-full h-2 bg-gray-200 rounded-full mt-2">
                      <div 
                        className="h-full bg-blue-500 rounded-full"
                        style={{ width: `${uploadProgress[fileItem.id]}%` }}
                      ></div>
                    </div>
                  )}
                  
                  {/* Show upload result */}
                  {uploadResults.find(r => r.id === fileItem.id) && (
                    <div className="mt-1">
                      {uploadResults.find(r => r.id === fileItem.id).success ? (
                        <span className="text-sm text-green-600 flex items-center gap-1">
                          <Check size={14} />
                          Uploaded successfully
                        </span>
                      ) : (
                        <span className="text-sm text-red-600 flex items-center gap-1">
                          <X size={14} />
                          {uploadResults.find(r => r.id === fileItem.id).error}
                        </span>
                      )}
                    </div>
                  )}
                </div>
                
                <button
                  onClick={() => removeFile(fileItem.id)}
                  disabled={uploading}
                  className={`p-2 rounded-full hover:bg-gray-100 
                    ${uploading ? 'text-gray-400 cursor-not-allowed' : 'text-gray-500'}`}
                >
                  <X size={16} />
                </button>
              </div>
            ))}
          </div>
        </div>
      )}
      
      {/* Upload results summary */}
      {uploadResults.length > 0 && (
        <div className="mt-8 p-4 bg-gray-50 rounded-lg">
          <h3 className="font-semibold mb-2">Upload Results</h3>
          <div className="flex gap-4">
            <div className="flex items-center gap-1">
              <Check size={16} className="text-green-500" />
              <span>{uploadResults.filter(r => r.success).length} successful</span>
            </div>
            <div className="flex items-center gap-1">
              <X size={16} className="text-red-500" />
              <span>{uploadResults.filter(r => !r.success).length} failed</span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SchematicUploader;