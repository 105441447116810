// Step 1: Update RequestContext.js to support pagination
import React, { createContext, useContext, useState, useEffect } from 'react';
import { collection, query, where, onSnapshot, orderBy, limit, startAfter, getDocs } from 'firebase/firestore';
import { db } from '../../services/firebase';

const RequestContext = createContext();

export const RequestProvider = ({ children }) => {
  const [requests, setRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  
  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRequests, setTotalRequests] = useState(0);
  const [lastVisible, setLastVisible] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const ITEMS_PER_PAGE = 10;

  // Get total count of requests (for pagination info)
  useEffect(() => {
    const fetchTotalCount = async () => {
      try {
        const q = query(
          collection(db, 'EnergisationRequest'),
          where('status', '!=', 'ENERGISATION')
        );
        
        const querySnapshot = await getDocs(q);
        setTotalRequests(querySnapshot.size);
      } catch (err) {
        console.error('Error fetching total count:', err);
      }
    };

    fetchTotalCount();
  }, []);

  // Fetch the first page of requests
  useEffect(() => {
    const fetchFirstPage = async () => {
      setLoading(true);
      try {
        const q = query(
          collection(db, 'EnergisationRequest'),
          where('status', '!=', 'ENERGISATION'),
          orderBy('status'), // First order by the inequality field
          orderBy('dateOfRequest', 'desc'), // Then order by date, newest first
          limit(ITEMS_PER_PAGE)
        );

        const querySnapshot = await getDocs(q);
        
        if (querySnapshot.empty) {
          setRequests([]);
          setHasMore(false);
          return;
        }
        
        // Get the last item for pagination
        const lastDoc = querySnapshot.docs[querySnapshot.docs.length - 1];
        setLastVisible(lastDoc);
        
        // Set hasMore based on whether we got back fewer items than the limit
        setHasMore(querySnapshot.docs.length === ITEMS_PER_PAGE);
        
        const fetchedRequests = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          requestedPowerOnDate: doc.data().requestedPowerOnDate?.toDate().toISOString().split('T')[0]
        }));
        
        setRequests(fetchedRequests);
      } catch (err) {
        console.error('Error fetching requests:', err);
        setError('Failed to fetch requests');
      } finally {
        setLoading(false);
      }
    };

    fetchFirstPage();
  }, []);

  // Function to load the next page
  const fetchNextPage = async () => {
    if (!hasMore || loading) return;
    
    setLoading(true);
    try {
      const q = query(
        collection(db, 'EnergisationRequest'),
        where('status', '!=', 'ENERGISATION'),
        orderBy('status'), // First order by the inequality field
        orderBy('dateOfRequest', 'desc'),
        startAfter(lastVisible),
        limit(ITEMS_PER_PAGE)
      );

      const querySnapshot = await getDocs(q);
      
      if (querySnapshot.empty) {
        setHasMore(false);
        return;
      }
      
      // Get the last visible item for next pagination
      const lastDoc = querySnapshot.docs[querySnapshot.docs.length - 1];
      setLastVisible(lastDoc);
      
      // Set hasMore based on whether we got back fewer items than requested
      setHasMore(querySnapshot.docs.length === ITEMS_PER_PAGE);
      
      const nextRequests = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        requestedPowerOnDate: doc.data().requestedPowerOnDate?.toDate().toISOString().split('T')[0]
      }));
      
      setRequests(nextRequests);
      setCurrentPage(prev => prev + 1);
    } catch (err) {
      console.error('Error fetching next page:', err);
      setError('Failed to fetch next page');
    } finally {
      setLoading(false);
    }
  };

  // Function to load the previous page
  const fetchPreviousPage = async () => {
    if (currentPage <= 1 || loading) return;
    
    setLoading(true);
    try {
      // We need to recalculate pages from the beginning to go back
      // This is a limitation with Firestore pagination
      const q = query(
        collection(db, 'EnergisationRequest'),
        where('status', '!=', 'ENERGISATION'),
        orderBy('status'), // First order by the inequality field
        orderBy('dateOfRequest', 'desc'),
        limit(ITEMS_PER_PAGE * (currentPage - 1))
      );

      const querySnapshot = await getDocs(q);
      
      if (querySnapshot.empty) {
        return;
      }
      
      // Calculate the starting point for the previous page
      const previousPageStartIndex = Math.max(0, querySnapshot.docs.length - ITEMS_PER_PAGE);
      const previousPageDocs = querySnapshot.docs.slice(previousPageStartIndex);
      
      // Get the last visible item for pagination
      const lastDoc = previousPageDocs[previousPageDocs.length - 1];
      setLastVisible(lastDoc);
      
      const previousPageRequests = previousPageDocs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        requestedPowerOnDate: doc.data().requestedPowerOnDate?.toDate().toISOString().split('T')[0]
      }));
      
      setRequests(previousPageRequests);
      setCurrentPage(prev => prev - 1);
      setHasMore(true); // If we can go back, there might be more items ahead
    } catch (err) {
      console.error('Error fetching previous page:', err);
      setError('Failed to fetch previous page');
    } finally {
      setLoading(false);
    }
  };

  const updateRequestStatus = async (requestId, newStatus) => {
    // Optimistic update
    setRequests(prevRequests =>
      prevRequests.map(request =>
        request.id === requestId
          ? { ...request, status: newStatus }
          : request
      )
    );

    // If status is ENERGISATION, remove from list
    if (newStatus === 'ENERGISATION') {
      setRequests(prevRequests => 
        prevRequests.filter(request => request.id !== requestId)
      );
      
      // Update total count
      setTotalRequests(prev => prev - 1);
    }
  };

  const value = {
    requests,
    loading,
    error,
    updateRequestStatus,
    currentPage,
    totalRequests,
    hasMore,
    itemsPerPage: ITEMS_PER_PAGE,
    fetchNextPage,
    fetchPreviousPage,
    totalPages: Math.ceil(totalRequests / ITEMS_PER_PAGE)
  };

  return (
    <RequestContext.Provider value={value}>
      {children}
    </RequestContext.Provider>
  );
};

export const useRequests = () => {
  const context = useContext(RequestContext);
  if (context === undefined) {
    throw new Error('useRequests must be used within a RequestProvider');
  }
  return context;
};