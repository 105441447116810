// src/components/Mimic/models/ElementTypes.js
export const ElementTypes = {
  RED_LINE: 'red_line',   // Energized
  GREEN_LINE: 'green_line' // Earth
};

// Line element model
export class Line {
  constructor(id, points, type = ElementTypes.RED_LINE) {
    this.id = id || `line-${Date.now()}-${Math.floor(Math.random() * 1000)}`;
    this.type = type;
    this.points = points; // Array of two points [start, end]
    this.color = type === ElementTypes.RED_LINE ? '#ff0000' : '#00cc00';
    this.thickness = 3;
  }
}