import React, { useState, useEffect } from 'react';
import { Mail, Clock, CheckCircle, XCircle, Send } from 'lucide-react';
import { toast } from 'react-hot-toast';
import { useRequests } from '../RequestContext';
import ConfirmationModal from '../../../utils/ConfirmationModal';
import EmailMultiSelect from '../../../utils/EmailMultiSelect';
import { approvalService } from '../../../services/approvalService';
import { emailListService } from '../../../services/emailListService';


const QaQcApprovalRequest = ({ request }) => {
  const { updateRequestStatus } = useRequests();
  const [selectedEmails, setSelectedEmails] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [approvalStatus, setApprovalStatus] = useState('pending');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [availableEmails, setAvailableEmails] = useState([]);

   // Load emails from Firestore
   useEffect(() => {
    const loadEmails = async () => {
      try {
        const emailList = await emailListService.getEmails('QAQC');
        setAvailableEmails(emailList.map(e => e.email));
      } catch (error) {
        toast.error('Failed to load email list');
        console.error('Error loading emails:', error);
      }
    };
    
    loadEmails();
  }, []);


  useEffect(() => {
    if (request.qaqcApprovalRequest) {
      if (request.qaqcApproval) {
        setApprovalStatus('approved');
      } else if (request.qaqcApprovalRequest.status === 'sent') {
        setApprovalStatus('sent');
      } else {
        setApprovalStatus('pending');
      }
    }
  }, [request]);

  const handleSubmitClick = (e) => {
    e.preventDefault();
    if (selectedEmails.length === 0) {
      toast.error('Please select at least one email recipient');
      return;
    }

    // Check if GC has approved
    if (!request.gccxApproval) {
      toast.error('GC approval is required before QA/QC review');
      return;
    }

    setIsModalOpen(true);
  };

  const handleConfirmSend = async () => {
    setIsModalOpen(false);
    setIsSubmitting(true);

    const submitPromise = new Promise(async (resolve, reject) => {
      try {
        const result = await approvalService.sendQaQcApprovalRequest(
          request.id,
          selectedEmails
        );

        await updateRequestStatus(request.id, 'PendingQaQcApproval');
        setApprovalStatus('sent');
        setSelectedEmails([]);
        resolve('QA/QC review requests sent successfully');
      } catch (error) {
        console.error('Error sending QA/QC review request:', error);
        reject(error.message);
      } finally {
        setIsSubmitting(false);
      }
    });

    toast.promise(submitPromise, {
      loading: 'Sending QA/QC review requests...',
      success: (message) => message,
      error: (err) => `Failed to send request: ${err}`
    });
  };

  const renderStatusBadge = () => {
    switch (approvalStatus) {
      case 'sent':
        return (
          <div className="flex items-center space-x-2 text-yellow-400">
            <Clock size={20} />
            <span>Review Request Sent</span>
          </div>
        );
      case 'approved':
        return (
          <div className="flex items-center space-x-2 text-green-400">
            <CheckCircle size={20} />
            <span>Review Complete</span>
          </div>
        );
      case 'expired':
        return (
          <div className="flex items-center space-x-2 text-red-400">
            <XCircle size={20} />
            <span>Link Expired</span>
          </div>
        );
      default:
        return null;
    }
  };

  // If GC hasn't approved yet, show waiting message with toast
  if (!request.gccxApproval) {
    return (
      <div className="space-y-4 p-4 bg-gray-700 rounded-lg">
        <div className="flex items-center space-x-2 text-yellow-400">
          <Clock size={20} />
          <span>Waiting for GC Approval</span>
        </div>
        <p className="text-sm text-gray-300">
          QA/QC review will be available after GC approval is complete.
        </p>
      </div>
    );
  }

  // If already reviewed, show review details
  if (approvalStatus === 'approved') {
    return (
      <div className="space-y-4 p-4 bg-gray-700 rounded-lg">
        <div className="flex justify-between items-center">
          <h3 className="text-lg font-medium text-white">QA/QC Review Status</h3>
          {renderStatusBadge()}
        </div>
        <div className="space-y-2">
          <p className="text-sm text-gray-300">Reviewed by: {request.qaqcApproval.name}</p>
          <p className="text-sm text-gray-300">
            Status: {request.qaqcApproval.status}
          </p>
          {request.qaqcApproval.comments && (
            <p className="text-sm text-gray-300">
              Comments: {request.qaqcApproval.comments}
            </p>
          )}
          <p className="text-sm text-gray-300">
            Review Date: {new Date(request.qaqcApproval.approvedAt).toLocaleDateString()}
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="space-y-4">
      <div className="flex justify-between items-center">
        <h3 className="text-lg font-medium text-white">Request QA/QC Review</h3>
        {renderStatusBadge()}
      </div>

      <form onSubmit={handleSubmitClick} className="space-y-4">
        <div className="space-y-2">
          <div className="space-y-2">
            <label className="block text-sm font-medium text-white">
              {approvalStatus === 'sent' ? 'Send to Additional Recipients' : 'Select Recipients'}
            </label>
            <EmailMultiSelect 
              availableEmails={availableEmails}
              selectedEmails={selectedEmails}
              setSelectedEmails={setSelectedEmails}
            />
          </div>
        </div>

        <button
          type="submit"
          disabled={isSubmitting || selectedEmails.length === 0}
          className={`w-full py-2 px-4 rounded-md transition-colors
            ${isSubmitting || selectedEmails.length === 0
              ? 'bg-gray-500 cursor-not-allowed' 
              : 'bg-blue-600 hover:bg-blue-700'}`}
        >
          {isSubmitting ? 'Sending Requests...' : 
           approvalStatus === 'sent' ? 'Send Additional Requests' : 'Send QA/QC Review Requests'}
        </button>

        {approvalStatus === 'sent' && (
          <div className="text-sm text-gray-400">
            <p>Review request has been sent to the selected QA/QC Team member</p>
            <p>Waiting for response...</p>
          </div>
        )}
      </form>

      <ConfirmationModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onConfirm={handleConfirmSend}
        title="Send QA/QC Review Request"
        message={`Are you sure you want to send review requests to ${selectedEmails.length} recipient${selectedEmails.length > 1 ? 's' : ''}?`}
        confirmText="Yes, Send Request"
        cancelText="No, Cancel"
      />
    </div>
  );
};

export default QaQcApprovalRequest;