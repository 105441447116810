// src/components/ETracker/AccessCodePage.js
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import logo from '../assets/logo.png'; // Update path as needed

const ACCESS_CODE = process.env.REACT_APP_TRACKER_ACCESS_CODE; // You can change this or store in environment variables
const ACCESS_TOKEN_KEY = 'energy_tracker_access';
const TOKEN_EXPIRY = 24 * 60 * 60 * 1000; // 24 hours in milliseconds

const AccessCodePage = () => {
  const [accessCode, setAccessCode] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // Check if they already have a valid token
    const storedToken = localStorage.getItem(ACCESS_TOKEN_KEY);
    
    if (storedToken) {
      try {
        const tokenData = JSON.parse(storedToken);
        const isExpired = new Date().getTime() > tokenData.expiresAt;
        
        if (!isExpired) {
          // Token is valid, redirect to the tracker
          navigate('/energisation-tracker-wxt/dashboard');
        }
      } catch (err) {
        // Invalid token format, remove it
        localStorage.removeItem(ACCESS_TOKEN_KEY);
      }
    }
  }, [navigate]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    // Simple validation
    if (!accessCode.trim()) {
      setError('Please enter an access code');
      setLoading(false);
      return;
    }

    // Check the access code
    if (accessCode === ACCESS_CODE) {
      // Create token with expiration
      const token = {
        authenticated: true,
        createdAt: new Date().getTime(),
        expiresAt: new Date().getTime() + TOKEN_EXPIRY
      };
      
      // Store token
      localStorage.setItem(ACCESS_TOKEN_KEY, JSON.stringify(token));
      
      // Redirect to tracker
      const from = new URLSearchParams(location.search).get('from') || '/energisation-tracker-wxt/dashboard';
      navigate(from);
    } else {
      setError('Invalid access code');
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gray-900 flex items-center justify-center p-4">
      <div className="max-w-md w-full bg-gray-800 rounded-lg shadow-xl p-8">
        <div className="flex justify-center mb-8">
          <img src={logo} alt="Company Logo" className="h-16 w-auto" />
        </div>
        
        <h2 className="text-2xl font-bold text-white text-center mb-6">
          Energisation Tracker Access
        </h2>
        
        <p className="text-gray-300 mb-6 text-center">
          Enter the access code to view the energisation tracker. If you need access, please contact SAST Office.
        </p>
        
        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label htmlFor="accessCode" className="block text-sm font-medium text-gray-300 mb-2">
              Access Code
            </label>
            <input
              id="accessCode"
              type="password"
              value={accessCode}
              onChange={(e) => setAccessCode(e.target.value)}
              className="w-full px-4 py-3 bg-gray-700 text-white border border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Enter access code"
            />
          </div>
          
          {error && (
            <div className="text-red-500 text-sm">
              {error}
            </div>
          )}
          
          <button
            type="submit"
            disabled={loading}
            className="w-full bg-blue-600 hover:bg-blue-700 text-white font-bold py-3 px-4 rounded-lg transition duration-300 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            {loading ? 'Verifying...' : 'Access Tracker'}
          </button>
        </form>
      </div>
    </div>
  );
};

export default AccessCodePage;