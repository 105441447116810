import React, { useState, useEffect } from 'react';
import {Clock, CheckCircle, XCircle, Send } from 'lucide-react';
import { toast } from 'react-hot-toast';
import { useRequests } from '../RequestContext';
import ConfirmationModal from '../../../utils/ConfirmationModal';
import EmailMultiSelect from '../../../utils/EmailMultiSelect';
import { approvalService } from '../../../services/approvalService';
import { emailListService } from '../../../services/emailListService';



const GcApprovalRequest = ({ request }) => {
  const { updateRequestStatus } = useRequests();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [approvalStatus, setApprovalStatus] = useState('pending');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedEmails, setSelectedEmails] = useState([]);
  const [availableEmails, setAvailableEmails] = useState([]);

  useEffect(() => {
    const loadEmails = async () => {
      try {
        const emailList = await emailListService.getEmails('GC');
        setAvailableEmails(emailList.map(e => e.email));
      } catch (error) {
        toast.error('Failed to load email list');
        console.error('Error loading emails:', error);
      }
    };
    
    loadEmails();
  }, []);
  
  useEffect(() => {
    
    if (request.gcApprovalRequest) {
      if (request.gccxApproval) {
        setApprovalStatus('approved');
      } else if (request.gcApprovalRequest.status === 'sent') {
        setApprovalStatus('sent');
      } else {
        setApprovalStatus('pending');
      }
    }
  }, [request]);

  const handleSubmitClick = (e) => {
    e.preventDefault();
    if (selectedEmails.length === 0) {
      toast.error('Please select at least one email recipient');
      return;
    }
    setIsModalOpen(true);
  };

  const handleConfirmSend = async () => {
    setIsModalOpen(false);
    setIsSubmitting(true);

    const submitPromise = new Promise(async (resolve, reject) => {
      try {
        const result = await approvalService.sendGcApprovalRequest(
          request.id, 
          selectedEmails
        );

        await updateRequestStatus(request.id, 'PendingGcApproval');
        setApprovalStatus('sent');
        setSelectedEmails([]); // Clear selection after successful send
        resolve('Approval requests sent successfully');
      } catch (error) {
        console.error('Error sending approval request:', error);
        reject(error.message);
      } finally {
        setIsSubmitting(false);
      }
    });

    toast.promise(submitPromise, {
      loading: 'Sending approval requests...',
      success: (message) => message,
      error: (err) => `Failed to send request: ${err}`
    });
  };

  const renderStatusBadge = () => {
    switch (approvalStatus) {
      case 'sent':
        return (
          <div className="flex items-center space-x-2 text-yellow-400">
            <Clock size={20} />
            <span>Approval Request Sent</span>
          </div>
        );
      case 'approved':
        return (
          <div className="flex items-center space-x-2 text-green-400">
            <CheckCircle size={20} />
            <span>Approved</span>
          </div>
        );
      case 'expired':
        return (
          <div className="flex items-center space-x-2 text-red-400">
            <XCircle size={20} />
            <span>Link Expired</span>
          </div>
        );
      default:
        return null;
    }
  };

  const renderEmailHistory = () => {
    const emailHistory = request.gcApprovalRequest?.emailHistory || [];
    if (emailHistory.length === 0) return null;

    return (
      <div className="mt-4 space-y-2 bg-gray-700 rounded-lg p-4">
        <h4 className="text-sm font-medium text-gray-300">Previously Sent To:</h4>
        <div className="space-y-2">
          {emailHistory.map((entry, index) => (
            <div key={index} className="flex items-center space-x-2 text-sm text-gray-400">
              <Send size={14} />
              <span>{entry.email}</span>
              <span>•</span>
              <span>{new Date(entry.sentAt.seconds * 1000).toLocaleDateString()}</span>
            </div>
          ))}
        </div>
      </div>
    );
  };

  // If already approved, show approval details and email history
  if (approvalStatus === 'approved') {
    return (
      <div className="space-y-4 p-4 bg-gray-700 rounded-lg">
        <div className="flex justify-between items-center">
          <h3 className="text-lg font-medium text-white">GC Approval Status</h3>
          {renderStatusBadge()}
        </div>
        <div className="space-y-2">
          <p className="text-sm text-gray-300">Approved by: {request.gccxApproval.name}</p>
          <p className="text-sm text-gray-300">
            Yellow Tag Status: {request.gccxApproval.yellowTag}
          </p>
          {request.gccxApproval.bimIssues && (
            <p className="text-sm text-gray-300">
              BIM Issues: {request.gccxApproval.bimIssues}
            </p>
          )}
          <p className="text-sm text-gray-300">
            Approved on: {new Date(request.gccxApproval.approvedAt).toLocaleDateString()}
          </p>
        </div>
        {renderEmailHistory()}
      </div>
    );
  }

  return (
    <div className="space-y-4">
      <div className="flex justify-between items-center">
        <h3 className="text-lg font-medium text-white">Request GC Approval</h3>
        {renderStatusBadge()}
      </div>

      {renderEmailHistory()}

      <form onSubmit={handleSubmitClick} className="space-y-4">
        <div className="space-y-2">
          <div className="space-y-2">
            <label className="block text-sm font-medium text-white">
              {approvalStatus === 'sent' ? 'Send to Additional Recipients' : 'Select Recipients'}
            </label>
            <EmailMultiSelect 
              availableEmails={availableEmails}
              selectedEmails={selectedEmails}
              setSelectedEmails={setSelectedEmails}
            />
          </div>
        </div>

        <button
          type="submit"
          disabled={isSubmitting || selectedEmails.length === 0}
          className={`w-full py-2 px-4 rounded-md transition-colors
            ${isSubmitting || selectedEmails.length === 0
              ? 'bg-gray-500 cursor-not-allowed' 
              : 'bg-blue-600 hover:bg-blue-700'}`}
        >
          {isSubmitting ? 'Sending Requests...' : 
           approvalStatus === 'sent' ? 'Send Additional Requests' : 'Send Approval Requests'}
        </button>
      </form>

      <ConfirmationModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onConfirm={handleConfirmSend}
        title="Send Approval Requests"
        message={`Are you sure you want to send approval requests to ${selectedEmails.length} recipient${selectedEmails.length > 1 ? 's' : ''}?`}
        confirmText="Yes, Send Requests"
        cancelText="No, Cancel"
      />
    </div>
  );
};

export default GcApprovalRequest;