// src/components/ETracker/ExternalCompletedTable.js
import React, { useState, useEffect } from 'react';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../../services/firebase';
import { generatePDF } from '../../utils/pdfGenerator';
import { ChevronLeft, ChevronRight } from 'lucide-react';

const ExternalCompletedTable = () => {
  const [completedRequests, setCompletedRequests] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 15;

  useEffect(() => {
    const fetchCompletedRequests = async () => {
      setLoading(true);
      try {
        const q = query(collection(db, 'EnergisationRequest'), where('status', '==', 'ENERGISATION'));
        const querySnapshot = await getDocs(q);
        const fetchedRequests = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          requestedPowerOnDate: doc.data().requestedPowerOnDate?.toDate().toISOString().split('T')[0]
        }));
        setCompletedRequests(fetchedRequests);
      } catch (error) {
        console.error('Error fetching completed requests:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchCompletedRequests();
  }, []);

  const handleGeneratePDF = async (request) => {
    try {
      const pdfBlob = await generatePDF(request);
      const pdfUrl = URL.createObjectURL(pdfBlob);
      window.open(pdfUrl, '_blank');
    } catch (error) {
      console.error('Error generating PDF:', error);
      alert('Failed to generate PDF. Please try again.');
    }
  };

  // Calculate pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = completedRequests.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(completedRequests.length / itemsPerPage);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const nextPage = () => setCurrentPage(prev => Math.min(prev + 1, totalPages));
  const prevPage = () => setCurrentPage(prev => Math.max(prev - 1, 1));

  if (loading) {
    return (
      <div className="flex justify-center items-center p-8">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="bg-gray-900 shadow-2xl rounded-lg w-full">
      <div className="p-6">
        <h2 className="text-2xl font-bold mb-6 text-white">Completed Requests</h2>
        
        {completedRequests.length === 0 ? (
          <div className="text-white text-center py-8">
            No completed requests found
          </div>
        ) : (
          <>
            <div className="overflow-x-auto">
              <table className="w-full text-sm text-left text-gray-300">
                <thead className="text-xs uppercase bg-gray-800/60 text-gray-300 border-b border-gray-700">
                  <tr className="backdrop-blur-sm">
                    <th scope="col" className="px-6 py-3">Asset Energised</th>
                    <th scope="col" className="px-6 py-3">Voltage</th>
                    <th scope="col" className="px-6 py-3">Location</th>
                    <th scope="col" className="px-6 py-3">Requested By</th>
                    <th scope="col" className="px-6 py-3">GC Approved By</th>
                    <th scope="col" className="px-6 py-3">QA/QC Approved By</th>
                    <th scope="col" className="px-6 py-3">SAP Approved By</th>
                    <th scope="col" className="px-6 py-3">Energised By</th>
                    <th scope="col" className="px-6 py-3">Energisation Date</th>
                    <th scope="col" className="px-6 py-3">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {currentItems.map((request) => (
                    <tr key={request.id} className="border-b bg-gray-800/40 border-gray-700 hover:bg-gray-700/50 transition-all duration-200">
                      <td className="px-6 py-4">{request.assetToEnergise || 'N/A'}</td>
                      <td className="px-6 py-4">{request.voltageRange || 'N/A'}</td>
                      <td className="px-6 py-4">{request.location || 'N/A'}</td>
                      <td className="px-6 py-4">{request.name || 'N/A'}</td>
                      <td className="px-6 py-4">{request.gccxApproval?.name || 'N/A'}</td>
                      <td className="px-6 py-4">{request.qaqcApproval?.name || 'N/A'}</td>
                      <td className="px-6 py-4">{request.sapApproval?.name || 'N/A'}</td>
                      <td className="px-6 py-4">{request.energisationApproval?.name || 'N/A'}</td>
                      <td className="px-6 py-4">{request.energisationApproval?.dateTime ? new Date(request.energisationApproval.dateTime).toLocaleDateString() : 'N/A'}</td>
                      <td className="px-6 py-4">
                        <button
                          onClick={() => handleGeneratePDF(request)}
                          className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition duration-300"
                        >
                          Generate PDF
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            
            {/* Pagination */}
            {totalPages > 1 && (
              <div className="flex items-center justify-between mt-6 px-4">
                <div className="text-sm text-gray-400">
                  Showing {indexOfFirstItem + 1}-{Math.min(indexOfLastItem, completedRequests.length)} of {completedRequests.length} requests
                </div>
                <div className="flex items-center space-x-2">
                  <button
                    onClick={prevPage}
                    disabled={currentPage === 1}
                    className="p-2 rounded-md bg-gray-700 text-white disabled:opacity-50 disabled:cursor-not-allowed"
                  >
                    <ChevronLeft size={18} />
                  </button>
                  
                  <div className="flex items-center space-x-1">
                    {Array.from({ length: totalPages }, (_, i) => i + 1).map(number => (
                      <button
                        key={number}
                        onClick={() => paginate(number)}
                        className={`w-8 h-8 rounded-md flex items-center justify-center ${
                          currentPage === number 
                            ? 'bg-blue-600 text-white' 
                            : 'bg-gray-700 text-gray-300 hover:bg-gray-600'
                        }`}
                      >
                        {number}
                      </button>
                    ))}
                  </div>
                  
                  <button
                    onClick={nextPage}
                    disabled={currentPage === totalPages}
                    className="p-2 rounded-md bg-gray-700 text-white disabled:opacity-50 disabled:cursor-not-allowed"
                  >
                    <ChevronRight size={18} />
                  </button>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ExternalCompletedTable;