// src/components/Mimic/Mimic.js
import React, { useRef, useEffect, useState } from 'react';
import { Save, Eye, EyeOff, Undo, Redo, Trash } from 'lucide-react';
import { toast } from 'react-hot-toast';
import { collection, getDocs, query, where, orderBy } from 'firebase/firestore';
import { ref, getDownloadURL } from 'firebase/storage';
import { db, storage } from '../../services/firebase';

// Import our components
import { MimicProvider, useMimic } from './contexts/MimicContext';
import { MimicCanvas } from './components/MimicCanvas';
import { ElementTypes } from './models/ElementTypes';

// Import your default background image
import hvmvBackground from './schematics/HVMVMimic.jpg'; // Adjust path as needed

// Main Mimic component wrapper with the MimicProvider
const Mimic = () => {
  return (
    <MimicProvider>
      <MimicContent />
    </MimicProvider>
  );
};

// The actual Mimic content using the mimic context
const MimicContent = () => {
  const { 
    state, 
    handleZoom, 
    setLineType,
    deleteLastLine,
    undo,
    redo,
    saveMimic, 
    loadMimic,
    toggleEditMode,
    setBackgroundImage
  } = useMimic();

  const {
    lines,
    editMode,
    currentLineType,
    scale,
    isDirty
  } = state;

  const containerRef = useRef(null);
  
  // Add state for schematics and current schematic
  const [schematics, setSchematics] = useState([]);
  const [currentSchematic, setCurrentSchematic] = useState(null);
  const [loadingSchematics, setLoadingSchematics] = useState(true);
  
  // Fetch available schematics
  useEffect(() => {
    const fetchSchematics = async () => {
      try {
        setLoadingSchematics(true);
        
        const schematicsRef = collection(db, 'schematics');
        const q = query(
          schematicsRef, 
          where('active', '==', true),
          orderBy('order', 'asc')
        );
        
        const snapshot = await getDocs(q);
        
        if (snapshot.empty) {
          console.log('No schematics found');
          setSchematics([]);
          setLoadingSchematics(false);
          return;
        }
        
        const schematicList = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        
        setSchematics(schematicList);
        
        // Set first schematic as default if available
        if (schematicList.length > 0 && !currentSchematic) {
          handleSchematicChange(schematicList[0].id);
        }
      } catch (error) {
        console.error('Error fetching schematics:', error);
        toast.error('Failed to load schematics');
      } finally {
        setLoadingSchematics(false);
      }
    };
    
    fetchSchematics();
  }, []);
  
  // Handle schematic change
  const handleSchematicChange = async (schematicId) => {
    try {
      const selectedSchematic = schematics.find(s => s.id === schematicId);
      
      if (!selectedSchematic) {
        console.error('Schematic not found:', schematicId);
        return;
      }
      
      // Load the schematic image
      const imageUrl = await getDownloadURL(ref(storage, selectedSchematic.storagePath));
      
      // Update state
      setCurrentSchematic(selectedSchematic);
      setBackgroundImage(imageUrl);
      
      // For now, we'll keep using the existing saveMimic/loadMimic
      // In a future implementation, we'll associate lines with specific schematics
      
      toast.success(`Loaded schematic: ${selectedSchematic.name}`);
    } catch (error) {
      console.error('Error changing schematic:', error);
      toast.error('Failed to load schematic');
    }
  };
  
  // Set the fixed background image on component mount - ONLY if no schematics
  useEffect(() => {
    // If we have no schematics, use the default background
    if (schematics.length === 0 && !loadingSchematics) {
      setBackgroundImage(hvmvBackground);
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schematics, loadingSchematics]);
  
  // Load saved data separately
  useEffect(() => {
    const loadSavedData = async () => {
      try {
        const result = await loadMimic();
        
        if (result && result.success) {
          toast.success("Loaded saved data");
        }
      } catch (error) {
        console.error("Error loading saved data:", error);
      }
    };
    
    loadSavedData();
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Handle save with toast notification
  const handleSave = async () => {
    const loadingToast = toast.loading('Saving...');
    try {
      const result = await saveMimic();
      toast.dismiss(loadingToast);
      
      if (result.success) {
        toast.success(result.message);
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      toast.dismiss(loadingToast);
      toast.error("Error saving data");
      console.error("Save error:", error);
    }
  };

  return (
    <div className="w-full h-full relative overflow-hidden border border-gray-300">
      {/* Top toolbar */}
      <div className="absolute top-0 left-0 right-0 h-12 bg-white border-b border-gray-300 px-4 flex items-center justify-between z-10">
        <div className="flex items-center space-x-4">
          <span className="font-medium">HVMV Schematic</span>
          
          {/* Schematic Selector Dropdown */}
          <div className="relative">
            <select
              className="appearance-none pl-3 pr-8 py-1 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              value={currentSchematic?.id || ""}
              onChange={(e) => handleSchematicChange(e.target.value)}
              disabled={loadingSchematics}
            >
              {loadingSchematics ? (
                <option>Loading...</option>
              ) : schematics.length === 0 ? (
                <option value="">No schematics available</option>
              ) : (
                <>
                  <option value="" disabled>Select a schematic</option>
                  {schematics.map(schematic => (
                    <option key={schematic.id} value={schematic.id}>
                      {schematic.name}
                    </option>
                  ))}
                </>
              )}
            </select>
            <div className="absolute inset-y-0 right-0 pr-2 flex items-center pointer-events-none">
              <svg
                className="h-4 w-4 text-gray-500"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
          </div>
          
          <button
            onClick={handleSave}
            disabled={!isDirty || !editMode}
            className={`px-3 py-1 rounded-md flex items-center space-x-1
              ${isDirty && editMode 
                ? 'bg-green-500 text-white hover:bg-green-600' 
                : 'bg-gray-200 text-gray-500 cursor-not-allowed'}`}
          >
            <Save size={16} />
            <span>Save</span>
          </button>
        </div>
        
        <div className="flex items-center space-x-2">
          <span className="text-gray-600 text-sm font-semibold">
            {lines.length} lines drawn
          </span>
          <button
            onClick={toggleEditMode}
            className="px-3 py-1 rounded-md flex items-center space-x-1 bg-blue-100 text-blue-800 hover:bg-blue-200"
          >
            {editMode ? (
              <>
                <Eye size={16} />
                <span>View Mode</span>
              </>
            ) : (
              <>
                <EyeOff size={16} />
                <span>Edit Mode</span>
              </>
            )}
          </button>
        </div>
      </div>

      {/* Left toolbar for drawing tools */}
      <div className="absolute left-0 top-12 bottom-0 w-12 bg-white border-r border-gray-200 flex flex-col items-center py-4 z-10">
        {/* Line Type Buttons */}
        <div className="mb-6">
          <button
            onClick={() => setLineType(ElementTypes.RED_LINE)}
            className={`w-8 h-8 mb-2 rounded-lg flex items-center justify-center ${
              currentLineType === ElementTypes.RED_LINE ? 'ring-2 ring-black' : ''
            }`}
            style={{ backgroundColor: '#ff0000' }}
            title="Energized Line (Red)"
          />
          <button
            onClick={() => setLineType(ElementTypes.GREEN_LINE)}
            className={`w-8 h-8 rounded-lg flex items-center justify-center ${
              currentLineType === ElementTypes.GREEN_LINE ? 'ring-2 ring-black' : ''
            }`}
            style={{ backgroundColor: '#00cc00' }}
            title="Earth Line (Green)"
          />
        </div>

        {/* Zoom Buttons */}
        <button
          onClick={() => handleZoom(0.1)}
          className="w-8 h-8 mb-2 bg-gray-200 text-gray-700 rounded-lg flex items-center justify-center"
          title="Zoom In"
        >
          +
        </button>
        <button
          onClick={() => handleZoom(-0.1)}
          className="w-8 h-8 mb-6 bg-gray-200 text-gray-700 rounded-lg flex items-center justify-center"
          title="Zoom Out"
        >
          -
        </button>

        {/* Edit Buttons */}
        <button
          onClick={undo}
          className="w-8 h-8 mb-2 bg-gray-200 text-gray-700 rounded-lg flex items-center justify-center"
          title="Undo"
          disabled={!editMode}
        >
          <Undo size={16} />
        </button>
        <button
          onClick={redo}
          className="w-8 h-8 mb-2 bg-gray-200 text-gray-700 rounded-lg flex items-center justify-center"
          title="Redo"
          disabled={!editMode}
        >
          <Redo size={16} />
        </button>
        <button
          onClick={deleteLastLine}
          className="w-8 h-8 bg-red-500 text-white rounded-lg flex items-center justify-center"
          title="Delete Last Line"
          disabled={!editMode || lines.length === 0}
        >
          <Trash size={16} />
        </button>
      </div>

      {/* Main drawing canvas */}
      <div 
        ref={containerRef} 
        className="relative w-full h-full overflow-auto pt-12 pl-12"
        style={{ 
          // Set min height to ensure container is visible even if image is loading
          minHeight: "500px"
        }}
      >
        {loadingSchematics && (
          <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-70 z-20">
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
          </div>
        )}
        
        {!loadingSchematics && schematics.length === 0 && (
          <div className="absolute inset-0 flex items-center justify-center bg-gray-100 z-10">
            <div className="text-center p-8">
              <p className="text-gray-500 mb-4">No schematics available</p>
              <p className="text-sm text-gray-400">Upload schematics from the admin panel</p>
            </div>
          </div>
        )}
        
        <MimicCanvas 
          editMode={editMode}
          currentLineType={currentLineType}
          scale={scale}
          backgroundImage={state.backgroundImage}
        />
      </div>
    </div>
  );
};

export default Mimic;