import React, { useState, useRef, useEffect } from 'react';
import { Check, ChevronDown, X } from 'lucide-react';

const EmailMultiSelect = ({ availableEmails, selectedEmails, setSelectedEmails }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const dropdownRef = useRef(null);

  // Filter emails based on search term
  const filteredEmails = availableEmails.filter(email => 
    email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const toggleEmail = (email) => {
    if (selectedEmails.includes(email)) {
      setSelectedEmails(selectedEmails.filter(e => e !== email));
    } else {
      setSelectedEmails([...selectedEmails, email]);
    }
  };

  const removeEmail = (emailToRemove) => {
    setSelectedEmails(selectedEmails.filter(email => email !== emailToRemove));
  };

  return (
    <div className="relative" ref={dropdownRef}>
      {/* Selected emails display */}
      <div className="flex flex-wrap gap-2 p-2 bg-gray-700 rounded-t-md min-h-12 border-b border-gray-600">
        {selectedEmails.map((email, index) => (
          <div
            key={index}
            className="flex items-center gap-1 px-2 py-1 bg-blue-600 text-white rounded-md"
          >
            <span className="text-sm">{email}</span>
            <button
              type="button"
              onClick={() => removeEmail(email)}
              className="text-white hover:text-gray-200"
            >
              <X size={16} />
            </button>
          </div>
        ))}
      </div>

      {/* Dropdown trigger */}
      <div
        className="flex items-center justify-between p-2 bg-gray-700 cursor-pointer hover:bg-gray-600"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="text-gray-400 text-sm">
          {selectedEmails.length === 0 ? 'Select emails' : `${selectedEmails.length} selected`}
        </span>
        <ChevronDown 
          size={20} 
          className={`text-gray-400 transition-transform ${isOpen ? 'rotate-180' : ''}`}
        />
      </div>

      {/* Dropdown menu */}
      {isOpen && (
        <div className="absolute z-50 w-full mt-1 bg-gray-700 rounded-md shadow-lg max-h-64 overflow-visible">
          {/* Search input */}
          <div className="p-2 border-b border-gray-600">
            <input
              type="text"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="Search emails..."
              className="w-full px-2 py-1 bg-gray-600 text-white rounded-md placeholder-gray-400"
            />
          </div>

          {/* Email options */}
          <div className="py-1">
            {filteredEmails.map((email, index) => (
              <div
                key={index}
                onClick={() => toggleEmail(email)}
                className="flex items-center px-4 py-2 hover:bg-gray-600 cursor-pointer"
              >
                <div className={`w-4 h-4 border rounded mr-3 flex items-center justify-center
                  ${selectedEmails.includes(email) ? 'bg-blue-600 border-blue-600' : 'border-gray-400'}`}
                >
                  {selectedEmails.includes(email) && <Check size={12} className="text-white" />}
                </div>
                <span className="text-white">{email}</span>
              </div>
            ))}
            {filteredEmails.length === 0 && (
              <div className="px-4 py-2 text-gray-400">
                No emails found
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default EmailMultiSelect;