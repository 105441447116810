import React, { useState, useEffect, useRef } from 'react';
import SignaturePad from 'react-signature-canvas';
import { Calendar } from 'lucide-react';
import { toast } from 'react-hot-toast';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../../../services/firebase';
import { useRequests } from '../RequestContext';

const SapForm = ({ request }) => {
  const { updateRequestStatus } = useRequests();
  const [formData, setFormData] = useState({
    approved: '',
    comments: '',
    name: '',
    dateTime: new Date().toISOString().slice(0, 16),
  });
  const signaturePad = useRef();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    if (request.sapApproval) {
      setFormData({
        approved: request.sapApproval.approved ? 'yes' : 'no',
        comments: request.sapApproval.comments || '',
        name: request.sapApproval.name || '',
        dateTime: request.sapApproval.dateTime || new Date().toISOString().slice(0, 16),
      });
      setIsSubmitted(true);
    }
  }, [request]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({ ...prevData, [name]: value }));
  };

  const validateForm = () => {
    if (!formData.approved) {
      toast.error('Please select an approval status');
      return false;
    }
    if (!formData.name.trim()) {
      toast.error('Please enter your name');
      return false;
    }
    if (!formData.dateTime) {
      toast.error('Please select a date and time');
      return false;
    }
    if (signaturePad.current?.isEmpty()) {
      toast.error('Please provide your signature');
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    setIsSubmitting(true);
    const submitPromise = new Promise(async (resolve, reject) => {
      try {
        const signatureDataUrl = signaturePad.current.toDataURL();
        const requestRef = doc(db, 'EnergisationRequest', request.id);

        const updateData = {
          sapApproval: {
            approved: formData.approved === 'yes',
            comments: formData.comments,
            name: formData.name,
            dateTime: formData.dateTime,
            signature: signatureDataUrl,
            approvedAt: new Date().toISOString(),
          },
          status: 'SAP',
        };

        await updateDoc(requestRef, updateData);
        await updateRequestStatus(request.id, 'SAP');
        setIsSubmitted(true);
        resolve('SAP approval submitted successfully');
      } catch (error) {
        console.error('Error submitting SAP approval:', error);
        reject(error.message);
      } finally {
        setIsSubmitting(false);
      }
    });

    toast.promise(submitPromise, {
      loading: 'Submitting SAP approval...',
      success: (message) => message,
      error: (err) => `Failed to submit SAP approval: ${err}`
    });
  };

  const renderField = (label, value) => (
    <div className="space-y-2">
      <label className="block text-sm font-medium text-white">{label}</label>
      <input
        type="text"
        value={value}
        readOnly
        className="w-full px-3 py-2 bg-gray-700 text-white rounded-md opacity-70"
      />
    </div>
  );

  if (isSubmitted) {
    return (
      <div className="space-y-4">
        {renderField("Approved", formData.approved === 'yes' ? 'Yes' : 'No')}
        {renderField("Comments", formData.comments)}
        {renderField("Name", formData.name)}
        {renderField("Date/Time", formData.dateTime)}
        <div className="space-y-2">
          <label className="block text-sm font-medium text-white">Signature</label>
          {request.sapApproval?.signature && (
            <img 
              src={request.sapApproval.signature} 
              alt="Signature" 
              className="border border-gray-600 rounded-lg bg-white p-2 max-h-32"
            />
          )}
        </div>
        <div className="text-green-500 font-medium">Form submitted successfully</div>
      </div>
    );
  }

  // Check if previous approvals are complete
  if (!request.gccxApproval || !request.qaqcApproval) {
    return (
      <div className="p-4 bg-gray-700 rounded-lg">
        <div className="flex items-center space-x-2 text-yellow-400">
          <Calendar size={20} />
          <span>Waiting for required approvals</span>
        </div>
        <p className="mt-2 text-sm text-gray-300">
          {!request.gccxApproval && !request.qaqcApproval 
            ? 'GC and QA/QC approvals are required before SAP submission'
            : !request.gccxApproval 
              ? 'GC approval is required before SAP submission'
              : 'QA/QC approval is required before SAP submission'}
        </p>
      </div>
    );
  }

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div className="space-y-2">
        <label className="block text-sm font-medium text-white">Approved *</label>
        <div className="flex space-x-4">
          <button
            type="button"
            onClick={() => setFormData(prev => ({ ...prev, approved: 'yes' }))}
            className={`px-4 py-2 rounded-md transition-colors ${
              formData.approved === 'yes' 
                ? 'bg-green-600 text-white' 
                : 'bg-gray-700 text-white hover:bg-gray-600'
            }`}
          >
            Yes
          </button>
          <button
            type="button"
            onClick={() => setFormData(prev => ({ ...prev, approved: 'no' }))}
            className={`px-4 py-2 rounded-md transition-colors ${
              formData.approved === 'no' 
                ? 'bg-red-600 text-white' 
                : 'bg-gray-700 text-white hover:bg-gray-600'
            }`}
          >
            No
          </button>
        </div>
      </div>

      <div className="space-y-2">
        <label htmlFor="name" className="block text-sm font-medium text-white">Name *</label>
        <input
          id="name"
          name="name"
          type="text"
          value={formData.name}
          onChange={handleInputChange}
          required
          className="w-full px-3 py-2 bg-gray-700 text-white rounded-md"
          placeholder="Enter your name"
        />
      </div>

      <div className="space-y-2">
        <label htmlFor="dateTime" className="block text-sm font-medium text-white">Date/Time *</label>
        <div className="relative">
          <input
            id="dateTime"
            name="dateTime"
            type="datetime-local"
            value={formData.dateTime}
            onChange={handleInputChange}
            required
            className="w-full px-3 py-2 bg-gray-700 text-white rounded-md"
          />
          <Calendar className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
        </div>
      </div>

      <div className="space-y-2">
        <label htmlFor="comments" className="block text-sm font-medium text-white">Comments</label>
        <textarea
          id="comments"
          name="comments"
          value={formData.comments}
          onChange={handleInputChange}
          className="w-full px-3 py-2 bg-gray-700 text-white rounded-md"
          placeholder="Enter comments"
          rows="3"
        />
      </div>

      <div className="space-y-2">
        <label className="block text-sm font-medium text-white">
          Signature *
        </label>
        <div className="border border-gray-600 rounded-lg bg-gray-700 p-2">
          <SignaturePad
            ref={signaturePad}
            canvasProps={{
              className: "w-full h-32 bg-white rounded-md"
            }}
          />
        </div>
        <button
          type="button"
          onClick={() => signaturePad.current.clear()}
          className="mt-2 px-4 py-2 text-sm text-gray-300 bg-gray-700 rounded-lg hover:bg-gray-600 transition duration-200"
        >
          Clear Signature
        </button>
      </div>

      <button
        type="submit"
        disabled={isSubmitting}
        className={`w-full py-2 rounded-md transition-colors ${
          isSubmitting
            ? 'bg-gray-500 cursor-not-allowed'
            : 'bg-green-600 hover:bg-green-700 text-white'
        }`}
      >
        {isSubmitting ? 'Submitting...' : 'Submit SAP Approval'}
      </button>
    </form>
  );
};

export default SapForm;