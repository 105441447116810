import React, { useState } from 'react';
import { collection, addDoc } from 'firebase/firestore';
import { db } from '../../services/firebase';
import ConfirmationModal from '../../utils/ConfirmationModal';
import logo from '../../components/assets/logo.png';
import ReCAPTCHA from 'react-google-recaptcha';

const EnergisationRequestForm = () => {
  const [loading, setLoading] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [resultMessage, setResultMessage] = useState({ title: '', message: '', isError: false });
  const [captchaToken, setCaptchaToken] = useState(null);
  
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    company: '',
    voltageRange: '',
    otherVoltage: '',
    assetToEnergise: '',
    location: '',
    sourceAssetTag: '',
    requestedPowerOnDate: '',
    energisationType: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setShowConfirmModal(true);
  };

  const submitForm = async () => {
    setShowConfirmModal(false);
    setLoading(true);
    
    try {
      // Check if CAPTCHA is completed
      if (!captchaToken) {
        throw new Error('Please complete the CAPTCHA verification');
      }
      const formattedValues = {
        ...formData,
        requestedPowerOnDate: new Date(formData.requestedPowerOnDate),
        dateOfRequest: new Date(),
        status: 'PENDING'
      };

      const docRef = await addDoc(collection(db, 'EnergisationRequest'), formattedValues);
      
      console.log('Energisation request submitted successfully with ID:', docRef.id);
      setResultMessage({
        title: 'Success',
        message: 'Your energisation request has been submitted successfully.'
      });
      
      // Reset form
      setFormData({
        name: '',
        email: '',
        company: '',
        voltageRange: '',
        otherVoltage: '',
        assetToEnergise: '',
        location: '',
        sourceAssetTag: '',
        requestedPowerOnDate: '',
        energisationType: ''
      });
    } catch (error) {
      console.error('Error submitting energisation request:', error);
      setResultMessage({
        title: 'Error',
        message: 'Failed to submit energisation request. Please try again.'
      });
    } finally {
      setLoading(false);
      setFormSubmitted(true);
    }
  };

  const inputStyle = "w-full p-3 bg-gray-700 text-white border border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-400";
  const labelStyle = "block text-sm font-medium text-gray-300 mb-2";
  const radioGroupStyle = "flex flex-col space-y-2 sm:flex-row sm:space-y-0 sm:space-x-6";
  const radioLabelStyle = "inline-flex items-center cursor-pointer";
  const radioInputStyle = "form-radio text-blue-500 focus:ring-blue-500 h-4 w-4 mr-2";

  if (formSubmitted) {
    return (
      <div className="min-h-screen bg-gray-900 p-8">
        <div className="max-w-4xl mx-auto bg-gray-800 rounded-xl shadow-2xl p-8 text-center">
          <div className="flex justify-center mb-8">
            <img src={logo} alt="Company Logo" className="h-16 w-auto" />
          </div>
          
          <div className={`text-2xl font-bold mb-4 ${resultMessage.isError ? 'text-red-500' : 'text-green-500'}`}>
            {resultMessage.title}
          </div>
          <p className="text-white text-lg mb-8">{resultMessage.message}</p>
          
          <button
            onClick={() => window.location.reload()}
            className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-3 px-6 rounded-lg transition duration-300"
          >
            Submit Another Request
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-900 p-8">
      <div className="max-w-4xl mx-auto bg-gray-800 rounded-xl shadow-2xl p-8">
        {/* Logo */}
        <div className="flex justify-center mb-8">
          <img
            src={logo}
            alt="Company Logo"
            className="h-16 w-auto"
          />
        </div>

        <h2 className="text-3xl font-bold text-white text-center mb-8">
          Energisation Request Form
        </h2>

        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <label htmlFor="name" className={labelStyle}>Name</label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className={inputStyle}
                required
              />
            </div>
            <div>
              <label htmlFor="email" className={labelStyle}>Requestor Email Address</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className={inputStyle}
                required
              />
            </div>
          </div>

          <div>
            <label htmlFor="company" className={labelStyle}>Company</label>
            <input
              type="text"
              id="company"
              name="company"
              value={formData.company}
              onChange={handleChange}
              className={inputStyle}
              required
            />
          </div>

          <div>
            <label htmlFor="voltageRange" className={labelStyle}>Voltage Range of Equipment</label>
            <select
              id="voltageRange"
              name="voltageRange"
              value={formData.voltageRange}
              onChange={handleChange}
              className={inputStyle}
              required
            >
              <option value="">Select voltage range</option>
              <option value="110VDC">Control Voltage - 110VDC</option>
              <option value="240-400VAC">Low Voltage - 240 ~ 400 VAC</option>
              <option value="10kV">Medium Voltage - 22kV</option>
              <option value="Other">Other</option>
            </select>
          </div>

          {formData.voltageRange === 'Other' && (
            <div>
              <label htmlFor="otherVoltage" className={labelStyle}>Please enter asset tag here</label>
              <input
                type="text"
                id="otherVoltage"
                name="otherVoltage"
                value={formData.otherVoltage}
                onChange={handleChange}
                className={inputStyle}
                required
              />
            </div>
          )}

          <div>
            <label htmlFor="assetToEnergise" className={labelStyle}>Asset to be energised</label>
            <input
              type="text"
              id="assetToEnergise"
              name="assetToEnergise"
              value={formData.assetToEnergise}
              onChange={handleChange}
              className={inputStyle}
              required
            />
          </div>

          <div>
            <label htmlFor="location" className={labelStyle}>
              Location of asset to be energised - (building, floor, room)
            </label>
            <input
              type="text"
              id="location"
              name="location"
              value={formData.location}
              onChange={handleChange}
              className={inputStyle}
              required
            />
          </div>

          <div>
            <label htmlFor="sourceAssetTag" className={labelStyle}>
              Source Asset Tag
              <span className="text-red-400 ml-2">
                (Required - Request will be rejected if not specified)
              </span>
            </label>
            <input
              type="text"
              id="sourceAssetTag"
              name="sourceAssetTag"
              value={formData.sourceAssetTag}
              onChange={handleChange}
              className={inputStyle}
              required
            />
          </div>

          <div>
            <label htmlFor="requestedPowerOnDate" className={labelStyle}>Requested Power on date</label>
            <input
              type="date"
              id="requestedPowerOnDate"
              name="requestedPowerOnDate"
              value={formData.requestedPowerOnDate}
              onChange={handleChange}
              min={new Date(Date.now() + 86400000).toISOString().split('T')[0]}
              className={inputStyle}
              required
            />
          </div>

          <div>
            <label className={labelStyle}>Select energisation type</label>
            <div className={radioGroupStyle}>
              {['temporary', 'permanent'].map((type) => (
                <label key={type} className={radioLabelStyle}>
                  <input
                    type="radio"
                    name="energisationType"
                    value={type}
                    checked={formData.energisationType === type}
                    onChange={handleChange}
                    className={radioInputStyle}
                    required
                  />
                  <span className="text-white capitalize">{type} energisation</span>
                </label>
              ))}
            </div>
          </div>

          {/* reCAPTCHA */}
          <div className="flex justify-center pt-4">
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
              onChange={setCaptchaToken}
              theme="dark"
            />
          </div>

          <button
            type="submit"
            disabled={loading || !captchaToken}
            className="w-full bg-blue-600 hover:bg-blue-700 text-white font-bold py-3 px-4 rounded-lg transition duration-300 disabled:opacity-50 disabled:cursor-not-allowed mt-8"
          >
            {loading ? (
              <span className="flex items-center justify-center">
                <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                Submitting...
              </span>
            ) : (
              'Submit Request'
            )}
          </button>
        </form>

        {/* Confirmation Modal */}
        <ConfirmationModal
          isOpen={showConfirmModal}
          onClose={() => setShowConfirmModal(false)}
          onConfirm={submitForm}
          title="Confirm Submission"
          message="Are you sure you want to submit this energisation request?"
          confirmText="Submit"
          cancelText="Cancel"
        />

      </div>
    </div>
  );
};

export default EnergisationRequestForm;
