import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID
};

// Cloud Functions URLs
export const functionUrls = {
  gcApproval: process.env.REACT_APP_GC_APPROVAL_FUNCTION,
  qaqcApproval: process.env.REACT_APP_QAQC_APPROVAL_FUNCTION
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);

// Helper function for Cloud Functions calls
export const callCloudFunction = async (functionUrl, data) => {
  try {
    // Get the current user's ID token
    const currentUser = auth.currentUser;
    let authHeader = {};
    
    if (currentUser) {
      const token = await currentUser.getIdToken();
      authHeader = { 'Authorization': `Bearer ${token}` };
    }

    const response = await fetch(functionUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...authHeader
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      const errorData = await response.json().catch(() => null);
      throw new Error(errorData?.error || `Request failed with status ${response.status}`);
    }

    return response.json();
  } catch (error) {
    console.error('Cloud Function call failed:', error);
    throw error;
  }
};