// src/components/Mimic/contexts/MimicContext.js
import React, { createContext, useContext, useReducer, useState, useCallback } from 'react';
import { ElementTypes } from '../models/ElementTypes';
import { saveMimicToDB, getLatestMimic } from '../services/mimicService';

// Define initial state
const initialState = {
  lines: [],
  history: [], // For undo/redo functionality
  historyIndex: -1,
  currentLineType: ElementTypes.RED_LINE,
  editMode: true,
  backgroundImage: null,
  scale: 1,
  isDirty: false,
};

// Define action types
const ActionTypes = {
  SET_LINE_TYPE: 'SET_LINE_TYPE',
  SET_SCALE: 'SET_SCALE',
  ADD_LINE: 'ADD_LINE',
  DELETE_LAST_LINE: 'DELETE_LAST_LINE',
  SET_BACKGROUND: 'SET_BACKGROUND',
  TOGGLE_EDIT_MODE: 'TOGGLE_EDIT_MODE',
  LOAD_MIMIC: 'LOAD_MIMIC',
  CLEAR_MIMIC: 'CLEAR_MIMIC',
  MARK_SAVED: 'MARK_SAVED',
  UNDO: 'UNDO',
  REDO: 'REDO'
};

// Define reducer function
function mimicReducer(state, action) {
  switch (action.type) {
    case ActionTypes.SET_LINE_TYPE:
      return {
        ...state,
        currentLineType: action.payload
      };

    case ActionTypes.SET_SCALE:
      return {
        ...state,
        scale: action.payload
      };

    case ActionTypes.ADD_LINE: {
      const newLines = [...state.lines, action.payload];
      const newHistory = [...state.history.slice(0, state.historyIndex + 1), newLines];
      
      return {
        ...state,
        lines: newLines,
        history: newHistory,
        historyIndex: newHistory.length - 1,
        isDirty: true
      };
    }

    case ActionTypes.DELETE_LAST_LINE: {
      if (state.lines.length === 0) return state;
      
      const newLines = state.lines.slice(0, -1);
      const newHistory = [...state.history.slice(0, state.historyIndex + 1), newLines];
      
      return {
        ...state,
        lines: newLines,
        history: newHistory,
        historyIndex: newHistory.length - 1,
        isDirty: true
      };
    }
    
    case ActionTypes.UNDO: {
      if (state.historyIndex <= 0) return state;
      
      const newIndex = state.historyIndex - 1;
      return {
        ...state,
        lines: state.history[newIndex] || [],
        historyIndex: newIndex,
        isDirty: true
      };
    }
    
    case ActionTypes.REDO: {
      if (state.historyIndex >= state.history.length - 1) return state;
      
      const newIndex = state.historyIndex + 1;
      return {
        ...state,
        lines: state.history[newIndex] || [],
        historyIndex: newIndex,
        isDirty: true
      };
    }

    case ActionTypes.SET_BACKGROUND:
      return {
        ...state,
        backgroundImage: action.payload
      };

    case ActionTypes.TOGGLE_EDIT_MODE:
      return {
        ...state,
        editMode: !state.editMode
      };

    case ActionTypes.LOAD_MIMIC:
      return {
        ...state,
        lines: action.payload.lines || [],
        // Don't change backgroundImage - keep current value
        history: [action.payload.lines || []],
        historyIndex: 0,
        isDirty: false
      };

    case ActionTypes.CLEAR_MIMIC:
      return {
        ...initialState,
        scale: state.scale,
        editMode: state.editMode,
        backgroundImage: state.backgroundImage,
        history: [[]],
        historyIndex: 0
      };

    case ActionTypes.MARK_SAVED:
      return {
        ...state,
        isDirty: false
      };

    default:
      return state;
  }
}


// Create context
const MimicContext = createContext();

// Custom hook for using the mimic context
export const useMimic = () => {
  const context = useContext(MimicContext);
  if (!context) {
    throw new Error('useMimic must be used within a MimicProvider');
  }
  return context;
};

// Provider component
export const MimicProvider = ({ children }) => {
  const [state, dispatch] = useReducer(mimicReducer, initialState);

  // Memoize these functions to prevent them from changing on each render
  
  // Handle zooming
  const handleZoom = useCallback((delta) => {
    dispatch({
      type: ActionTypes.SET_SCALE,
      payload: Math.max(0.1, Math.min(3, state.scale + delta))
    });
  }, [state.scale]);

  // Set line type
  const setLineType = useCallback((lineType) => {
    dispatch({
      type: ActionTypes.SET_LINE_TYPE,
      payload: lineType
    });
  }, []);

  // Add line
  const addLine = useCallback((line) => {
    dispatch({
      type: ActionTypes.ADD_LINE,
      payload: line
    });
  }, []);

  // Delete last line
  const deleteLastLine = useCallback(() => {
    dispatch({
      type: ActionTypes.DELETE_LAST_LINE
    });
  }, []);

  // Undo last action
  const undo = useCallback(() => {
    dispatch({
      type: ActionTypes.UNDO
    });
  }, []);
  
  // Redo last undone action
  const redo = useCallback(() => {
    dispatch({
      type: ActionTypes.REDO
    });
  }, []);

  // Toggle edit mode
  const toggleEditMode = useCallback(() => {
    dispatch({ type: ActionTypes.TOGGLE_EDIT_MODE });
  }, []);

  // Set background image
  const setBackgroundImage = useCallback((imageUrl) => {
    dispatch({
      type: ActionTypes.SET_BACKGROUND,
      payload: imageUrl
    });
  }, []);

  // Load the most recent mimic from database
  const loadMimic = useCallback(async () => {
    try {
      const mimicData = await getLatestMimic();
      
      if (mimicData && mimicData.lines) {
        dispatch({
          type: ActionTypes.LOAD_MIMIC,
          payload: {
            lines: mimicData.lines || []
          }
        });
        return { success: true, message: "Loaded saved data" };
      }
      
      return { success: false, message: "No saved data found" };
    } catch (error) {
      console.error("Error loading mimic:", error);
      return { success: false, message: "Error loading data" };
    }
  }, []);

  // Save mimic to database
  const saveMimic = useCallback(async () => {
    try {
      // Just save the lines - we're using a fixed background
      await saveMimicToDB(state.lines);
      
      dispatch({ type: ActionTypes.MARK_SAVED });
      return { success: true, message: "Saved successfully" };
    } catch (error) {
      console.error("Error saving mimic:", error);
      return { success: false, message: "Error saving data" };
    }
  }, [state.lines]);

  // Clear current mimic
  const clearMimic = useCallback(() => {
    dispatch({ type: ActionTypes.CLEAR_MIMIC });
  }, []);

  // Create a stable context value
  const contextValue = React.useMemo(() => ({
    state,
    dispatch,
    handleZoom,
    setLineType,
    addLine,
    deleteLastLine,
    undo,
    redo,
    toggleEditMode,
    setBackgroundImage,
    loadMimic,
    saveMimic,
    clearMimic
  }), [
    state,
    handleZoom,
    setLineType,
    addLine,
    deleteLastLine,
    undo,
    redo,
    toggleEditMode,
    setBackgroundImage,
    loadMimic,
    saveMimic,
    clearMimic
  ]);

  return (
    <MimicContext.Provider value={contextValue}>
      {children}
    </MimicContext.Provider>
  );
};