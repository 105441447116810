import React, { useState, useEffect } from 'react';
import { useAuth } from '../../../contexts/AuthContext';
import { usePermitContext } from '../PermitsStatus/context/PermitContext';
import PermitService from '../PermitsStatus/services/PermitService';

const EPTWEditPermitModal = ({ isOpen, onClose, onEdit, permitData }) => {
  const { currentUser } = useAuth();
  const { refreshPermits } = usePermitContext();
  const [formData, setFormData] = useState({
    location: '',
    equipmentIdentification: '',
    workToBeDone: '',
    pointsOfIsolation: '',
    primaryEarthingDevices: '',
    actionsToAvoidDanger: '',
    furtherPrecautions: '',
    variablePrecautions: '',
    attachedICC: ''
  });
  const [isICCCompleted, setIsICCCompleted] = useState(false);

  useEffect(() => {
    if (permitData) {
      setFormData({
        location: permitData.location || '',
        equipmentIdentification: permitData.equipmentIdentification || '',
        workToBeDone: permitData.workToBeDone || '',
        pointsOfIsolation: permitData.pointsOfIsolation || '',
        primaryEarthingDevices: permitData.primaryEarthingDevices || '',
        actionsToAvoidDanger: permitData.actionsToAvoidDanger || '',
        furtherPrecautions: permitData.furtherPrecautions || '',
        variablePrecautions: permitData.variablePrecautions || '',
        attachedICC: permitData.attachedICC || ''
      });
      setIsICCCompleted(!!permitData.attachedICC);
    }
  }, [permitData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleICCCheckboxChange = (e) => {
    setIsICCCompleted(e.target.checked);
    if (!e.target.checked) {
      setFormData(prev => ({
        ...prev,
        attachedICC: ''
      }));
    }
  };

  const handleSubmit = async () => {
    try {
      await PermitService.editPermit(permitData.id, formData, currentUser?.uid);
      onEdit && onEdit({
        id: permitData.id,
        ...formData
      });
      refreshPermits();
      onClose();
    } catch (error) {
      console.error('Error editing EPTW permit:', error);
    }
  };

  if (!isOpen) return null;

  return (
    <div 
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
      onClick={(e) => e.target === e.currentTarget && onClose()}
    >
      <div className="bg-gray-900 rounded-lg shadow-xl w-full max-w-4xl border border-gray-700">
        {/* Header */}
        <div className="flex justify-between items-center p-4 border-b border-gray-700">
          <h2 className="text-xl font-semibold text-gray-100">
            Edit EPTW Permit
          </h2>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-200 transition-colors"
          >
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>

        {/* Content */}
        <div className="p-4 max-h-[70vh] overflow-y-auto">
          <div className="space-y-6">
            <div className="bg-gray-900 rounded-xl shadow-lg border border-gray-700">
              <div className="grid gap-6 p-4">
                {/* Section 1: Basic Information */}
                <div>
                  <h3 className="text-lg font-medium text-gray-200 mb-4">Section 1: Basic Information</h3>
                  <div className="space-y-4">
                    <div>
                      <label className="block text-sm font-medium text-gray-300 mb-2">(I) Location</label>
                      <input
                        name="location"
                        value={formData.location}
                        onChange={handleInputChange}
                        placeholder="Enter location details"
                        className="w-full px-4 py-3 bg-gray-800 border border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent text-gray-100 placeholder-gray-500 transition duration-200"
                      />
                    </div>

                    <div>
                      <label className="block text-sm font-medium text-gray-300 mb-2">(II) Equipment Identification</label>
                      <input
                        name="equipmentIdentification"
                        value={formData.equipmentIdentification}
                        onChange={handleInputChange}
                        placeholder="Enter equipment ID or description"
                        className="w-full px-4 py-3 bg-gray-800 border border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent text-gray-100 placeholder-gray-500 transition duration-200"
                      />
                    </div>

                    <div>
                      <label className="block text-sm font-medium text-gray-300 mb-2">(III) Work To Be Done</label>
                      <textarea
                        name="workToBeDone"
                        value={formData.workToBeDone}
                        onChange={handleInputChange}
                        placeholder="Describe the work in detail"
                        rows="4"
                        className="w-full px-4 py-3 bg-gray-800 border border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent text-gray-100 placeholder-gray-500 transition duration-200 resize-none"
                      />
                    </div>
                  </div>
                </div>

                {/* Section 2: Safety Controls */}
                <div>
                  <h3 className="text-lg font-medium text-gray-200 mb-4">Section 2: Safety Controls</h3>
                  <div className="space-y-4">
                    <div>
                      <label className="block text-sm font-medium text-gray-300 mb-2">(I) Points of Isolation</label>
                      <textarea
                        name="pointsOfIsolation"
                        value={formData.pointsOfIsolation}
                        onChange={handleInputChange}
                        placeholder="List all isolation points"
                        rows="3"
                        className="w-full px-4 py-3 bg-gray-800 border border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent text-gray-100 placeholder-gray-500 transition duration-200 resize-none"
                      />
                    </div>

                    <div>
                      <label className="block text-sm font-medium text-gray-300 mb-2">(II) Primary Earthing Device(s)</label>
                      <textarea
                        name="primaryEarthingDevices"
                        value={formData.primaryEarthingDevices}
                        onChange={handleInputChange}
                        placeholder="Specify earthing devices and their locations"
                        rows="3"
                        className="w-full px-4 py-3 bg-gray-800 border border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent text-gray-100 placeholder-gray-500 transition duration-200 resize-none"
                      />
                    </div>

                    <div>
                      <label className="block text-sm font-medium text-gray-300 mb-2">(III) Actions Taken to Avoid Danger</label>
                      <textarea
                        name="actionsToAvoidDanger"
                        value={formData.actionsToAvoidDanger}
                        onChange={handleInputChange}
                        placeholder="Detail actions"
                        rows="4"
                        className="w-full px-4 py-3 bg-gray-800 border border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent text-gray-100 placeholder-gray-500 transition duration-200 resize-none"
                      />
                    </div>

                    <div>
                      <label className="block text-sm font-medium text-gray-300 mb-2">(IV) Further Precautions During Work</label>
                      <textarea
                        name="furtherPrecautions"
                        value={formData.furtherPrecautions}
                        onChange={handleInputChange}
                        placeholder="List precautions to avoid system derived hazards during work"
                        rows="4"
                        className="w-full px-4 py-3 bg-gray-800 border border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent text-gray-100 placeholder-gray-500 transition duration-200 resize-none"
                      />
                    </div>

                    <div>
                      <label className="block text-sm font-medium text-gray-300 mb-2">(V) Variable Precautions</label>
                      <textarea
                        name="variablePrecautions"
                        value={formData.variablePrecautions}
                        onChange={handleInputChange}
                        placeholder="List precautions that may be varied (approved procedure required)"
                        rows="4"
                        className="w-full px-4 py-3 bg-gray-800 border border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent text-gray-100 placeholder-gray-500 transition duration-200 resize-none"
                      />
                    </div>

                    <div className="space-y-3">
                      <div className="flex items-center">
                        <input
                          type="checkbox"
                          id="iccCompleted"
                          checked={isICCCompleted}
                          onChange={handleICCCheckboxChange}
                          className="w-4 h-4 text-blue-600 bg-gray-800 border-gray-600 rounded focus:ring-blue-500"
                        />
                        <label htmlFor="iccCompleted" className="ml-2 text-sm font-medium text-gray-300">
                          (VI) Has ICC been completed?
                        </label>
                      </div>
                      
                      {isICCCompleted && (
                        <div>
                          <textarea
                            name="attachedICC"
                            value={formData.attachedICC}
                            onChange={handleInputChange}
                            placeholder="Enter ICC details"
                            rows="3"
                            className="w-full px-4 py-3 bg-gray-800 border border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent text-gray-100 placeholder-gray-500 transition duration-200 resize-none"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Footer */}
        <div className="flex justify-end gap-2 p-4 border-t border-gray-700">
          <button
            onClick={onClose}
            className="px-4 py-2 text-sm font-medium text-gray-200 bg-gray-700 border border-gray-600 rounded-md hover:bg-gray-600"
          >
            Cancel
          </button>
          <button
            onClick={handleSubmit}
            className="px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md hover:bg-blue-500"
          >
            Save Changes
          </button>
        </div>
      </div>
    </div>
  );
};

export default EPTWEditPermitModal;