// src/components/Mimic/components/MimicCanvas.js
import React, { useRef, useCallback } from 'react';
import { useMimic } from '../contexts/MimicContext';
import { useDrawingManager } from '../managers/DrawingManager';
import { LineRenderer } from './renderers/LineRenderer';

export const MimicCanvas = ({ 
  editMode, 
  currentLineType, 
  scale, 
  backgroundImage 
}) => {
  const { 
    state, 
    addLine
  } = useMimic();

  const { lines } = state;
  
  // Handle line creation callback - using useCallback to prevent recreation on each render
  const handleLineCreated = useCallback((line) => {
    addLine(line);
  }, [addLine]);

  // Set up drawing manager
  const {
    svgRef,
    isDrawing,
    currentLine,
    startPoint,
    showGuides,
    startDrawing,
    draw,
    endDrawing,
    resetDrawing
  } = useDrawingManager(scale, handleLineCreated);

  // Mouse event handlers for drawing
  const handleMouseDown = (e) => {
    if (!editMode) return;
    startDrawing(e, currentLineType);
  };

  // Calculate canvas dimensions based on background image
  const canvasWidth = 5520; // Match your specified image width
  const canvasHeight = 2003; // Match your specified image height

  return (
    <div 
      style={{ 
        transform: `scale(${scale})`,
        transformOrigin: 'top left',
        width: `${canvasWidth}px`,
        height: `${canvasHeight}px`,
        position: 'relative'
      }}
    >
      {/* Background Image */}
      {backgroundImage && (
        <img
          src={backgroundImage}
          alt="Schematic Background"
          className="absolute top-0 left-0 w-full h-full object-contain"
        />
      )}
      
      {/* SVG Drawing Surface */}
      <svg 
        ref={svgRef}
        width={canvasWidth} 
        height={canvasHeight} 
        className="absolute top-0 left-0"
        onMouseDown={handleMouseDown}
        onMouseMove={(e) => draw(e)}
        onMouseUp={endDrawing}
        onMouseLeave={resetDrawing}
      >
        {/* Drawing Guides */}
        {showGuides && startPoint && (
          <>
            <line
              x1="0" y1={startPoint.y}
              x2={canvasWidth} y2={startPoint.y}
              stroke="#666"
              strokeWidth="1"
              strokeDasharray="5,5"
              opacity="0.5"
            />
            <line
              x1={startPoint.x} y1="0"
              x2={startPoint.x} y2={canvasHeight}
              stroke="#666"
              strokeWidth="1"
              strokeDasharray="5,5"
              opacity="0.5"
            />
          </>
        )}
        
        {/* Render Lines */}
        {lines && lines.map((line) => (
          <g key={line.id}>
            <LineRenderer
              points={line.points}
              color={line.color}
              width={line.thickness || 3}
            />
          </g>
        ))}
        
        {/* Current Drawing Line */}
        {currentLine && currentLine.points && (
          <LineRenderer
            points={currentLine.points}
            color={currentLine.color}
            width={currentLine.thickness || 3}
            dashed={true}
          />
        )}
      </svg>
    </div>
  );
};