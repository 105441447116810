import React from 'react';
import BaseModal from '../../../utils/baseModal';
import EmailListManager from './EmailListManager';

const EmailManagerModal = ({ isOpen, onClose, userRole }) => {
  return (
    <BaseModal
      isOpen={isOpen}
      onClose={onClose}
      title="Manage Email Lists"
      maxWidth="2xl"
    >
      <div className="space-y-6">
        <EmailListManager type="GC" userRole={userRole} />
        <EmailListManager type="QAQC" userRole={userRole} />
      </div>
    </BaseModal>
  );
};

export default EmailManagerModal;