import React, { createContext, useState, useEffect, useContext } from 'react';
import { auth, db } from '../services/firebase';
import { onAuthStateChanged, updateProfile } from 'firebase/auth';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { useLocation, useNavigate } from 'react-router-dom';

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [authError, setAuthError] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();

  // Define public paths and their access rules
  const publicPaths = {
    // Authentication routes
    '/login': { requiresAuth: false },
    '/register': { requiresAuth: false },
    
    // Request forms
    '/cp-request': { requiresAuth: false },
    '/permit-requests': { requiresAuth: false },
    '/energisation-request': { requiresAuth: false },

    '/energisation-tracker-wxt/access': { requiresAuth: false },
'/energisation-tracker-wxt/dashboard': { 
  requiresAuth: false

},
    
    // External approval forms
    '/gc-approval': { requiresAuth: false },
    '/qaqc-approval': { requiresAuth: false },
    
    // Special routes with state validation
    '/cptest': { 
      requiresAuth: false,
      requiresState: true,
      validateState: (state) => state?.registrationId && 
                              state?.email && 
                              state?.isExternalUser
    }
  };

  // Check if current path is public
  const isPublicPath = (path) => {
    const normalizedPath = path.toLowerCase();
    return Object.keys(publicPaths).some(publicPath => 
      normalizedPath.startsWith(publicPath.toLowerCase())
    );
  };

  // Get current path configuration
  const getCurrentPathConfig = () => {
    const normalizedPath = location.pathname.toLowerCase();
    return Object.entries(publicPaths).find(([path]) => 
      normalizedPath.startsWith(path.toLowerCase())
    )?.[1] || { requiresAuth: true };
  };

  // Validate access to current path
  const validateAccess = () => {
    const pathConfig = getCurrentPathConfig();
    
    if (!pathConfig.requiresAuth) {
      if (pathConfig.requiresState && !pathConfig.validateState(location.state)) {
        navigate('/cp-request', { replace: true });
        return false;
      }
      return true;
    }

    if (!currentUser && !isPublicPath(location.pathname)) {
      navigate('/login', { 
        replace: true,
        state: { from: location.pathname }
      });
      return false;
    }

    return true;
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      try {
        if (user) {
          const userDoc = await getDoc(doc(db, 'users', user.uid));
          const userData = userDoc.data();
          
          setCurrentUser({
            ...user,
            role: userData?.role || 'user',
            cpCertified: userData?.cpCertified || false,
            lastLogin: userData?.lastLogin || null
          });
        } else {
          setCurrentUser(null);
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
        setAuthError('Error loading user data. Please try again.');
      } finally {
        setLoading(false);
      }
    });

    return () => unsubscribe();
  }, []);

  // Validate access whenever location changes
  useEffect(() => {
    if (!loading) {
      validateAccess();
    }
  }, [location.pathname, currentUser, loading]);

  const updateUserProfile = async (profileData) => {
    if (!currentUser) throw new Error('No user logged in');

    try {
      // Update displayName in Firebase Auth
      if (profileData.name) {
        await updateProfile(auth.currentUser, {
          displayName: profileData.name
        });
      }

      // Update additional data in Firestore
      const userRef = doc(db, 'users', currentUser.uid);
      await updateDoc(userRef, {
        displayName: profileData.name,
        phoneNumber: profileData.phoneNumber,
        company: profileData.company,
        signature: profileData.signature,
        updatedAt: new Date()
      });

      // Update local user state
      setCurrentUser(prev => ({
        ...prev,
        displayName: profileData.name,
        phoneNumber: profileData.phoneNumber,
        company: profileData.company,
        signature: profileData.signature
      }));

    } catch (error) {
      console.error('Error updating profile:', error);
      throw new Error(error.message);
    }
  };

  const value = {
    currentUser,
    loading,
    authError,
    updateUserProfile,
    isPublicPath: isPublicPath(location.pathname),
    isAuthenticated: !!currentUser,
    pathRequiresAuth: getCurrentPathConfig().requiresAuth
  };

  if (loading) {
    return <div>Loading...</div>; // Consider replacing with a proper loading component
  }

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
}

// Custom hook for external user session
export function useExternalUserSession() {
  const location = useLocation();
  const navigate = useNavigate();

  const isValidExternalUser = location.state?.isExternalUser && 
    location.state?.registrationId && 
    location.state?.email;

  const clearExternalSession = () => {
    navigate('/cp-request', { replace: true });
  };

  return {
    isValidExternalUser,
    externalUserData: isValidExternalUser ? location.state : null,
    clearExternalSession
  };
}
