// src/components/Mimic/managers/DrawingManager.js
import { useState, useRef } from 'react';
import { ElementTypes, Line } from '../models/ElementTypes';

export const useDrawingManager = (scale = 1, onLineCreated) => {
  const [isDrawing, setIsDrawing] = useState(false);
  const [currentLine, setCurrentLine] = useState(null);
  const [startPoint, setStartPoint] = useState(null);
  const [showGuides, setShowGuides] = useState(false);
  const [drawingType, setDrawingType] = useState(ElementTypes.RED_LINE);
  const svgRef = useRef(null);

  // Get coordinates from mouse event
  const getCoordinates = (event) => {
    if (!svgRef.current) return null;
    
    const svg = svgRef.current;
    const rect = svg.getBoundingClientRect();
    return {
      x: (event.clientX - rect.left) / scale,
      y: (event.clientY - rect.top) / scale
    };
  };

  // Start drawing a line
  const startDrawing = (event, lineType) => {
    const point = getCoordinates(event);
    if (!point) return;

    setDrawingType(lineType);
    setIsDrawing(true);
    setStartPoint(point);
    setCurrentLine({
      points: [point, { ...point }],
      type: lineType,
      color: lineType === ElementTypes.RED_LINE ? '#ff0000' : '#00cc00',
      thickness: 3
    });
    setShowGuides(true);
  };

  // Continue drawing as mouse moves
  const draw = (event) => {
    if (!isDrawing || !startPoint) return;
    
    const currentPoint = getCoordinates(event);
    if (!currentPoint) return;

    const dx = Math.abs(currentPoint.x - startPoint.x);
    const dy = Math.abs(currentPoint.y - startPoint.y);
    
    // Snap to horizontal or vertical based on movement
    let endPoint;
    if (dx > dy) {
      endPoint = { x: currentPoint.x, y: startPoint.y };
    } else {
      endPoint = { x: startPoint.x, y: currentPoint.y };
    }
    
    setCurrentLine(prev => {
      if (!prev) return null;
      return {
        ...prev,
        points: [startPoint, endPoint]
      };
    });
  };

  // End drawing and create element
  const endDrawing = () => {
    if (!currentLine || !currentLine.points || currentLine.points.length < 2) {
      resetDrawing();
      return;
    }
    
    const start = currentLine.points[0];
    const end = currentLine.points[1];
    
    // Check if line has length (not just a click)
    if (Math.abs(start.x - end.x) < 5 && Math.abs(start.y - end.y) < 5) {
      resetDrawing();
      return;
    }
    
    // Create a new line
    const newLine = new Line(
      null,
      [start, end],
      drawingType
    );
    
    // Notify parent component about the new line
    if (onLineCreated) {
      onLineCreated(newLine);
    }
    
    resetDrawing();
  };

  // Reset drawing state
  const resetDrawing = () => {
    setIsDrawing(false);
    setCurrentLine(null);
    setStartPoint(null);
    setShowGuides(false);
  };

  return {
    svgRef,
    isDrawing,
    currentLine,
    startPoint,
    showGuides,
    startDrawing,
    draw,
    endDrawing,
    resetDrawing
  };
};