import React, { useState } from 'react';
import { FaEye, FaCheckCircle, FaTimesCircle, FaExchangeAlt, FaClipboardCheck, FaEdit, FaUserCheck } from 'react-icons/fa';
import IssuePermitModal from '../modals/IssuePermitModal';
import ClosePermitModal from '../modals/ClosePermitModal';
import TransferPermitModal from '../modals/TransferPermitModal';
import ClearancePermitModal from '../modals/ClearancePermitModal';
import EditPermitModal from '../modals/EditPermitModal';
import EPTWEditPermitModal from '../modals/EPTWEditPermitModal';
import ControlEngineerConsentModal from '../modals/ControlEngineerConsentModal';

const PermitActions = ({ 
  permit, 
  handleViewPermit, 
  handleIssuePermit, 
  handleClosePermit,
  handleTransferPermit,
  handleClearancePermit,
  handleEditPermit,
  handleControlEngineerConsent
}) => {
  const [isIssueModalOpen, setIsIssueModalOpen] = useState(false);
  const [isCloseModalOpen, setIsCloseModalOpen] = useState(false);
  const [isTransferModalOpen, setIsTransferModalOpen] = useState(false);
  const [isClearanceModalOpen, setIsClearanceModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isConsentModalOpen, setIsConsentModalOpen] = useState(false);

  // Modal open/close handlers
  const handleOpenIssueModal = () => setIsIssueModalOpen(true);
  const handleCloseIssueModal = () => setIsIssueModalOpen(false);
  
  const handleOpenCloseModal = () => setIsCloseModalOpen(true);
  const handleCloseCloseModal = () => setIsCloseModalOpen(false);

  const handleOpenTransferModal = () => setIsTransferModalOpen(true);
  const handleCloseTransferModal = () => setIsTransferModalOpen(false);

  const handleOpenClearanceModal = () => setIsClearanceModalOpen(true);
  const handleCloseClearanceModal = () => setIsClearanceModalOpen(false);
  
  const handleOpenEditModal = () => setIsEditModalOpen(true);
  const handleCloseEditModal = () => setIsEditModalOpen(false);
  
  const handleOpenConsentModal = () => setIsConsentModalOpen(true);
  const handleCloseConsentModal = () => setIsConsentModalOpen(false);

  // Action handlers
  const handleIssueConfirm = async (permitData) => {
    await handleIssuePermit(permitData);
    handleCloseIssueModal();
  };

  const handleCloseConfirm = async (closeData) => {
    console.log('PermitActions - handleCloseConfirm received:', closeData);
    try {
      if (!closeData?.permitId || !closeData?.closeDetails) {
        console.error('PermitActions - Invalid close data:', closeData);
        return;
      }
      
      const success = await handleClosePermit(closeData);
      if (success) {
        handleCloseCloseModal();
      }
    } catch (error) {
      console.error('PermitActions - Error in handleCloseConfirm:', error);
    }
  };

  const handleTransferConfirm = async (transferData) => {
    console.log('PermitActions - handleTransferConfirm called with:', transferData);
    try {
      await handleTransferPermit(transferData);
      handleCloseTransferModal();
    } catch (error) {
      console.error('PermitActions - Error in handleTransferConfirm:', error);
    }
  };

  const handleClearanceConfirm = async (clearanceData) => {
    console.log('PermitActions - handleClearanceConfirm called with:', clearanceData);
    try {
      if (!handleClearancePermit) {
        console.error('PermitActions - handleClearancePermit prop is not defined');
        return;
      }
      await handleClearancePermit(clearanceData);
      handleCloseClearanceModal();
    } catch (error) {
      console.error('PermitActions - Error in handleClearanceConfirm:', error);
    }
  };
  
  const handleConsentConfirm = async (consentData) => {
    console.log('PermitActions - handleConsentConfirm called with:', consentData);
    try {
      if (!handleControlEngineerConsent) {
        console.error('PermitActions - handleControlEngineerConsent prop is not defined');
        return;
      }
      await handleControlEngineerConsent(consentData);
      handleCloseConsentModal();
    } catch (error) {
      console.error('PermitActions - Error in handleConsentConfirm:', error);
    }
  };

  const handleEditConfirm = async (editedData) => {
    try {
      await handleEditPermit(editedData);
      handleCloseEditModal();
    } catch (error) {
      console.error('PermitActions - Error in handleEditConfirm:', error);
    }
  };

  // Check if consent is needed (for EPTW) and not already given
  const needsConsent = permit.type === 'EPTW' && 
                      permit.status === 'Prepared' && 
                      !permit.consentDetails;

  // Determine if this is an EPTW permit                    
  const isEPTW = permit.type === 'EPTW';

  return (
    <div className="flex items-center space-x-2">
      {/* Edit button - only for Prepared permits */}
      {permit.status === 'Prepared' && (
        <button
          onClick={handleOpenEditModal}
          className="text-yellow-500 hover:text-yellow-700"
          title="Edit Permit"
        >
          <FaEdit />
        </button>
      )}

      {/* View button - always visible */}
      <button
        onClick={() => handleViewPermit(permit)}
        className="text-blue-500 hover:text-blue-700"
        title="View Permit"
      >
        <FaEye />
      </button>
      
      {/* Control Engineer Consent button - only for EPTW Prepared permits that don't have consent yet */}
      {needsConsent && (
        <button
          onClick={handleOpenConsentModal}
          className="text-purple-500 hover:text-purple-700"
          title="Control Engineer Consent"
        >
          <FaUserCheck />
        </button>
      )}

      {/* Issue button - only for:
          - Prepared non-EPTW permits, or
          - EPTW permits that have consent */}
      {permit.status === 'Prepared' && 
        (permit.type !== 'EPTW' || (permit.type === 'EPTW' && permit.consentDetails)) && (
        <button
          onClick={handleOpenIssueModal}
          className="text-green-500 hover:text-green-700"
          title="Issue Permit"
        >
          <FaCheckCircle />
        </button>
      )}

      {/* Transfer button - only for Issued permits */}
      {permit.status === 'Issued' && (
        <button
          onClick={handleOpenTransferModal}
          className="text-yellow-500 hover:text-yellow-700"
          title="Transfer Permit"
        >
          <FaExchangeAlt />
        </button>
      )}

      {/* Clearance button - for Issued or Expired permits */}
      {(permit.status === 'Issued' || permit.status === 'Expired') && (
        <button
          onClick={handleOpenClearanceModal}
          className="text-indigo-500 hover:text-indigo-700"
          title="Clear Permit"
        >
          <FaClipboardCheck />
        </button>
      )}

      {/* Close button - for Clearance status AND Prepared status */}
{(permit.status === 'Clearance' || permit.status === 'Prepared') && (
  <button
    onClick={handleOpenCloseModal}
    className="text-red-500 hover:text-red-700"
    title="Close Permit"
  >
    <FaTimesCircle />
  </button>
)}
      
      {/* Modals */}
      {/* Conditionally render either standard Edit Modal or EPTW Edit Modal based on permit type */}
      {isEPTW ? (
        <EPTWEditPermitModal
          isOpen={isEditModalOpen}
          onClose={handleCloseEditModal}
          onEdit={handleEditConfirm}
          permitData={permit}
        />
      ) : (
        <EditPermitModal
          isOpen={isEditModalOpen}
          onClose={handleCloseEditModal}
          onEdit={handleEditConfirm}
          permitData={permit}
        />
      )}

      <IssuePermitModal
        isOpen={isIssueModalOpen}
        onClose={handleCloseIssueModal}
        onIssue={handleIssueConfirm}
        permitData={permit}
      />

      <TransferPermitModal
        isOpen={isTransferModalOpen}
        onClose={handleCloseTransferModal}
        onTransfer={handleTransferConfirm}
        permitData={permit}
      />

      <ClearancePermitModal
        isOpen={isClearanceModalOpen}
        onClose={handleCloseClearanceModal}
        onClearance={handleClearanceConfirm}
        permitData={permit}
      />

      <ClosePermitModal
        isOpen={isCloseModalOpen}
        onClose={handleCloseCloseModal}
        onClosePermit={handleCloseConfirm}
        permitData={permit}
      />
      
      <ControlEngineerConsentModal
        isOpen={isConsentModalOpen}
        onClose={handleCloseConsentModal}
        onConsent={handleConsentConfirm}
        permitData={permit}
      />
    </div>
  );
};

export default PermitActions;