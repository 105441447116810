'use client';

const TestIntroduction = ({ onStartTest, darkMode = true }) => {
  return (
    <div className={`${darkMode ? 'bg-gray-800 text-white' : 'bg-white text-gray-900'} p-6 rounded-lg shadow-lg`}>
      <h2 className="text-2xl font-bold mb-4">Welcome to the CP Test</h2>
      <p className="mb-4">Please read the following instructions carefully:</p>
      <ul className="list-disc pl-5 mb-6 space-y-2">
        <li>This test consists of multiple-choice questions</li>
        <li>Each question can have one or multiple correct answers</li>
        <li>A minimum score of 75% is required to pass</li>
        <li>You have a maximum of 2 attempts to pass the test</li>
        <li>There is no time limit, but try to complete the test in one sitting</li>
        <li>Your results will be displayed at the end of the test</li>
      </ul>
      <button 
        className={`px-6 py-2 rounded ${
          darkMode 
            ? 'bg-blue-600 hover:bg-blue-700' 
            : 'bg-blue-500 hover:bg-blue-600'
        } text-white transition-colors`}
        onClick={onStartTest}
      >
        Start Test
      </button>
    </div>
  );
};

export default TestIntroduction;