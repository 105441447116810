export const loaTemplates = {
    standard: {
      name: "Standard Work",
      limitsOfWork: `WORK SCOPE IS AS PER SECTION 1 OF THIS DOCUMENT. NO ACCESS TO ELECTRICAL PANELS DURING THIS WORK OTHER THAN
  THOSE SPECIFIED IN SECTION 1 (iii). NO OTHER WORKS ALLOWED UNDER THIS DOCUMENT. PERSONAL SUPERVISION TO BE
  CARRIED OUT BY THE SAFETY DOCUMENT RECIPIENT. THESE ROOMS ALL CONTAIN LIVE EQUIPMENT AT EITHER 110 VOLTS, 416
  VOLTS OR 22,000 VOLTS, SO EXTREME CARE TO BE TAKEN DURING THESE WORKS. CONSULT SAP/AP IF IN ANY DOUBT ABOUT
  WORK SCOPE OR RESTRICTIONS.`,
      furtherPrecautions: `ALL WORK TO BE CARRIED OUT IN ACCORDANCE WITH APPROVED RAMS {{rams}} NO SHARP TOOLS OR POWER TOOLS TO BE
  USED IN CLOSE PROXIMITY TO TEMPORARY POWER CABLES, WHICH ARE ALL LIVE AT 110/416 VOLTS.
  NOTE: THE 22,000 VOLT TRANSFORMERS AND ALL SWITCHGEAR IS TO BE TREATED AS LIVE, SO BE AWARE OF ALL SIGNAGE
  STATING DANGER HIGH VOLTAGE. ALL LV TEMPORARY CABLES TO BE CONSIDERED LIVE AT 110/416 VOLTS. ALL TESTING TO BE
  CARRIED OUT USING APPROVED/ CALIBRATED TEST EQUIPMENT AFTER THE LEADS HAVE BEEN INSPECTED BEFORE EVERY USE.`
    },
    Scaffolders: {
      name: "Scaffolders",
      limitsOfWork: `WORK SCOPE IS AS PER SECTION 1 OF THIS DOCUMENT. NO OTHER WORKS ALLOWED UNDER THIS DOCUMENT. IMMEDIATE SUPERVISION TO BE CARRIED OUT BY THE SAFETY DOCUMENT RECIPIENT. THESE LOCATIONS CONTAIN ENERGISED SERVICES SO EXTREME CARE TO BE TAKEN DURING THESE WORKS. CONSULT SAP/AP IF IN ANY DOUBT ABOUT WORK SCOPE OR RESTRICTIONS.

DISMANTLING OF SCAFFOLD ONLY PERMITTED IN AREA DETAILED IN SECTION 1
DAMAGE TO ANY SERVICES MUST BE REPORTED TO SAP OFFICE IMMEDIATELY
NO TRESTLES/CONTAINMENT TO BE USED AS MEANS OF ACCESS.`,
      furtherPrecautions: `ALL WORK TO BE CARRIED OUT IN ACCORDANCE WITH APPROVED RAMS:{{rams}}. NO SHARP TOOLS OR POWER TOOLS TO BE USED IN CLOSE PROXIMITY TO ENERGISED POWER CABLES, WHICH ARE ALL LIVE AT XXXX VOLTS.`
    },
    Cleaning: {
      name: "Cleaning",
      limitsOfWork: `WORK SCOPE IS AS PER SECTION 1 OF THIS DOCUMENT. ACCESS TO WALLS & FLOOR ONLY, GENERAL CLEANING AND HOOVERING USING VACUUM CLEANER AND HAND CLEANING PRODUCTS ONLY.
CONSULT SAP/AP IF IN ANY DOUBT ABOUT WORK SCOPE OR RESTRICTIONS.
CLEANING IS NOT PERMITTED INSIDE ANY ELECTRICAL PANELS OR SWITCHGEAR.
SAST CONTROLLED AREAS ARE TO BE TREATED AS LIVE
THESE ROOMS CONTAIN BATTERIES,NO WORK TO BE DONE DIRECTLY OVER LIVE BATTERIES UNLESS SAST AP/SAP IS PRESENT.
SAFETY DOCUMENT HOLDER TO REMAIN ON SITE AT ALL TIMES CARRYING OUT IMMEDIATE SUPERVISION AS REQUIRED`,
      furtherPrecautions: `ALL WORK TO BE CARRIED OUT IN ACCORDANCE WITH APPROVED RAMS {{rams}}.`
    },
    Battery: {
      name: "Battery",
      limitsOfWork: `WORK SCOPE IS AS PER SECTION 1 OF THIS DOCUMENT. MINOR SNAGS ON BATTERY MONITORING SYSTEM INCLUDING BATTERY REPLACEMENT (AS PER AGREED LIST). NO ACCESS TO ANY OTHER EQUIPMENT UNDER THIS DOCUMENT.
CONSULT SAP/AP IF IN ANY DOUBT ABOUT WORK SCOPE OR RESTRICTIONS.
REPLACEMENT BATTERY WORK NOT TO BE CARRIED OUT WITHOUT A SAST SAP/AP IN ATTENDANCE
ANY WASTE ASSOCIATED WITH BATTERIES MUST BE REMOVED FROM AREA AT REGULAR INTERVALS.
ARC FLASH RATED PPE TO BE WORN, AND ADDITIONAL UPRATED PPE TO BE WORN WHEN WORKING WITH BATTERIES (GAUNTLETS, VISOR)`,
      furtherPrecautions: `ALL WORK TO BE CARRIED OUT IN ACCORDANCE WITH APPROVED RAMS {{rams}}. NO SHARP TOOLS OR POWER TOOLS TO BE USED IN CLOSE PROXIMITY TO ENERGISED POWER CABLES, WHICH ARE ALL LIVE AT XXXX VOLTS.
NOTE: BE AWARE OF ALL SIGNAGE STATING DANGER HIGH VOLTAGE. ALL TESTING TO BE CARRIED OUT USING APPROVED/ CALIBRATED TEST EQUIPMENT AFTER THE LEADS HAVE BEEN INSPECTED BEFORE EVERY USE.`
    },
    Firestopping: {
      name: "Firestopping",
      limitsOfWork: `WORK SCOPE IS AS PER SECTION 1 OF THIS DOCUMENT. ACCESS TO OPENINGS BETWEEN WALLS/FLOORS/CEILINGS  ONLY.
CONSULT SAP/AP IF IN ANY DOUBT ABOUT WORK SCOPE OR RESTRICTIONS.
DAMAGE TO ANY SERVICES MUST BE REPORTED TO SAP OFFICE IMMEDIATELY
NO WORKING AROUND LIVE EQUIPMENT - CONTACT SAP OFFICE!
WORK ONLY IN AREA SPECIFIED IN THE SAFETY DOCUMENT!
TREAT ALL SERVICES AS LIVE!
IF USING A MEWP, MUST BE CLEAN, SPOTTER TO BE IN PLACE AND BUMPERS TO BE USED`,
      furtherPrecautions: `ALL WORK TO BE CARRIED OUT IN ACCORDANCE WITH APPROVED RAMS:{{rams}}. NO SHARP TOOLS OR POWER TOOLS TO BE USED IN CLOSE PROXIMITY TO ENERGISED POWER CABLES, WHICH ARE ALL LIVE AT XXXX VOLTS.`
    },
    CommissioningLvl3: {
      name: "Commissioning lvl 3",
      limitsOfWork: `WORK SCOPE IS AS PER SECTION 1 OF THIS DOCUMENT. NO ACCESS TO PANELS DURING THIS WORK OTHER THAN SPECIFIED INDICATED WITHIN LEVEL 3 SCRIPT. ONLY WORKS ASSOCIATED WITH LEVEL 3 TESTS CAN BE CARRIED OUT  UNDER THIS DOCUMENT. THESE ROOMS ALL CONTAIN LIVE EQUIPMENT SO EXTREME CARE TO BE TAKEN DURING THESE WORKS. CONSULT SAP/AP IF IN ANY DOUBT ABOUT WORK SCOPE OR RESTRICTIONS.
APPROVED LEVEL 3 SCRIPT TO BE AVAILABLE ON SITE AT ALL TIMES`,
      furtherPrecautions: `ALL WORK TO BE CARRIED OUT IN ACCORDANCE WITH APPROVED RAMS {{rams}}. NO SHARP TOOLS OR POWER TOOLS TO BE USED IN CLOSE PROXIMITY TO ENERGISED POWER CABLES, WHICH ARE ALL LIVE.
NOTE: BE AWARE OF ALL SIGNAGE STATING DANGER HIGH VOLTAGE. ALL TESTING TO BE CARRIED OUT USING APPROVED/     CALIBRATED TEST EQUIPMENT AFTER THE LEADS HAVE BEEN INSPECTED BEFORE EVERY USE.`
    },
    CommissioningLvl4: {
      name: "Commissioning lvl 4",
      limitsOfWork: `WORK SCOPE IS AS PER SECTION 1 OF THIS DOCUMENT. NO ACCESS TO PANELS DURING THIS WORK OTHER THAN SPECIFIED INDICATED WITHIN LEVEL 4 SCRIPT. ONLY WORKS ASSOCIATED WITH LEVEL 4 TESTS CAN BE CARRIED OUT  UNDER THIS DOCUMENT. THESE ROOMS ALL CONTAIN LIVE EQUIPMENT SO EXTREME CARE TO BE TAKEN DURING THESE WORKS. CONSULT SAP/AP IF IN ANY DOUBT ABOUT WORK SCOPE OR RESTRICTIONS.
APPROVED LEVEL 4 SCRIPT TO BE AVAILABLE ON SITE AT ALL TIMES`,
      furtherPrecautions: `ALL WORK TO BE CARRIED OUT IN ACCORDANCE WITH APPROVED RAMS {{rams}}. NO SHARP TOOLS OR POWER TOOLS TO BE USED IN CLOSE PROXIMITY TO ENERGISED POWER CABLES, WHICH ARE ALL LIVE.
NOTE: BE AWARE OF ALL SIGNAGE STATING DANGER HIGH VOLTAGE. ALL TESTING TO BE CARRIED OUT USING APPROVED/CALIBRATED TEST EQUIPMENT AFTER THE LEADS HAVE BEEN INSPECTED BEFORE EVERY USE.`
    }

  };