// src/components/ETracker/ExternalTabbedContainer.js
import React, { useState } from 'react';
import { Toaster } from 'react-hot-toast';
import ExternalRequestsTable from './ExternalRequestsTable';
import ExternalCompletedTable from './ExternalCompletedTable';

const ExternalTabbedContainer = () => {
  const [activeTab, setActiveTab] = useState('inProgress');

  return (
    <div className="bg-gray-900 min-h-screen">
      <Toaster
        position="top-right"
        toastOptions={{
          duration: 4000,
          style: {
            background: '#1F2937',
            color: '#fff',
          },
          success: {
            iconTheme: {
              primary: '#10B981',
              secondary: '#fff',
            },
          },
          error: {
            iconTheme: {
              primary: '#EF4444',
              secondary: '#fff',
            },
          },
        }}
      />
      
      <div className="w-full py-8">
        <div className="mb-4 px-4">
          <button
            className={`px-6 py-3 mr-2 rounded-t-lg text-lg font-medium ${
              activeTab === 'inProgress'
                ? 'bg-blue-600 text-white'
                : 'bg-gray-700 text-gray-300 hover:bg-gray-600'
            }`}
            onClick={() => setActiveTab('inProgress')}
          >
            In Progress Requests
          </button>
          <button
            className={`px-6 py-3 rounded-t-lg text-lg font-medium ${
              activeTab === 'completed'
                ? 'bg-blue-600 text-white'
                : 'bg-gray-700 text-gray-300 hover:bg-gray-600'
            }`}
            onClick={() => setActiveTab('completed')}
          >
            Completed Requests
          </button>
        </div>
        <div className="bg-gray-800 rounded-b-lg p-6 w-full">
          {activeTab === 'inProgress' ? (
            <ExternalRequestsTable />
          ) : (
            <ExternalCompletedTable />
          )}
        </div>
      </div>
    </div>
  );
};

export default ExternalTabbedContainer;