import { functionUrls, callCloudFunction } from './firebase';

export const approvalService = {
  sendGcApprovalRequest: async (requestId, emails) => {
    return callCloudFunction(functionUrls.gcApproval, {
      requestId,
      emails,
    });
  },

  sendQaQcApprovalRequest: async (requestId, emails) => {
    return callCloudFunction(functionUrls.qaqcApproval, {
      requestId,
      emails,
    });
  },
};