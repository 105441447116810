// src/components/Mimic/components/renderers/LineRenderer.js
import React from 'react';

export const LineRenderer = ({ 
  points, 
  color = '#000000', 
  width = 2, 
  dashed = false,
  onClick
}) => {
  // Remove all console.log statements to prevent infinite rendering
  
  if (!points || points.length < 2) {
    return null;
  }

  // Create the path data for the line
  const pathData = `M ${points[0].x} ${points[0].y} L ${points[1].x} ${points[1].y}`;

  return (
    <>
      <path
        d={pathData}
        stroke={color}
        strokeWidth={width}
        strokeDasharray={dashed ? '5,5' : 'none'}
        fill="none"
      />
      
      {/* Add invisible larger areas for better clicking experience */}
      {onClick && (
        <>
          <circle
            cx={points[0].x}
            cy={points[0].y}
            r="8"
            fill="transparent"
            cursor="pointer"
            onClick={() => onClick(true)}
          />
          <circle
            cx={points[1].x}
            cy={points[1].y}
            r="8"
            fill="transparent"
            cursor="pointer"
            onClick={() => onClick(false)}
          />
        </>
      )}
    </>
  );
};