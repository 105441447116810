import React, { useState } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import Navbar from './components/Layout/Navbar';
import Sidebar from './components/Layout/Sidebar';
import Login from './components/Login';
import Register from './components/Register';
import Dashboard from './components/Dashboard/Dashboard';
import Permits from './components/Permits/Permits';
import PermitRequests from './components/Permits/PermitRequests';
import PrivateRoute from './components/PrivateRoute';
import AssetRegister from './components/AssetRegister/AssetRegister';
import Audits from './components/Audits/Audits';
import Mimic from './components/Mimic/Mimic';
import CPRequestForm from './components/CP/CPRequestForm';
import CPTest from './components/CP/CPTest';
import Etracker from './components/Etracker/Etracker';
import CPRegister from './components/CPRegister/CPRegister';
import Account from './components/Account';
import ExternalGcApprovalForm from './components/Etracker/ExpandedRowForms/external/ExternalGcApprovalForm';
import ExternalQaQcApprovalForm from './components/Etracker/ExpandedRowForms/external/ExternalQaQcApprovalForm';
import EnergisationRequestForm from './components/Etracker/EnergisationRequestForm';
import ExternalEtracker from './components/externalTracker/ExternalEtracker';
import RequireAccess from './components/externalTracker/RequireAccess';
import AccessCodePage from './components/externalTracker/AccessCodePage';
import SchematicAdmin from './components/Admin/SchematicAdmin';
function App() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className="App">
      <Routes>
        {/* Public Routes */}
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/cp-request" element={<CPRequestForm />} />
        <Route path="/permit-requests" element={<PermitRequests />} />
        <Route path="/energisation-request" element={<EnergisationRequestForm />} />
        <Route path="/cptest" element={
          <PublicTestRoute>
            <CPTest />
          </PublicTestRoute>
        } />

     <Route path="/energisation-tracker-wxt/access" element={<AccessCodePage />} />
<Route path="/energisation-tracker-wxt/dashboard" element={
  <RequireAccess>
    <div className="min-h-screen bg-gray-900 py-8">
      <div className="max-w-7xl mx-auto px-4">
        <ExternalEtracker />
      </div>
    </div>
  </RequireAccess>
} />
<Route path="/energisation-tracker-wxt" element={<Navigate to="/energisation-tracker-wxt/dashboard" replace />} />

        {/* External Forms */}
        <Route path="/gc-approval" element={
          <div className="min-h-screen bg-gray-900 py-8">
            <div className="max-w-7xl mx-auto px-4">
              <ExternalGcApprovalForm />
            </div>
          </div>
        } />
        <Route path="/qaqc-approval" element={
          <div className="min-h-screen bg-gray-900 py-8">
            <div className="max-w-7xl mx-auto px-4">
              <ExternalQaQcApprovalForm />
            </div>
          </div>
        } />

        {/* Protected Routes */}
        <Route path="/*" element={
          <PrivateRoute>
            <div className="flex h-screen">
              <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
              <div className="flex flex-col flex-1">
                <Navbar />
                <main className="flex-1 bg-gray-100 p-4">
                  <Routes>
                    <Route path="/dashboard" element={<Dashboard />} />
                    <Route path="/permits" element={<Permits />} />
                    <Route path="/assetregister" element={<AssetRegister />} />
                    <Route path="/audits" element={<Audits />} />
                    <Route path="/mimic" element={<Mimic />} />
                    <Route path="/etracker" element={<Etracker />} />
                    <Route path="/cp-register" element={<CPRegister />} />
                    <Route path="/account" element={<Account />} />
                    <Route path="/" element={<Navigate to="/dashboard" replace />} />
                    <Route
                        path="/admin/schematics"
                        element={
                          
                            <SchematicAdmin />
                         
                        }
                      />
                  </Routes>
                </main>
              </div>
            </div>
          </PrivateRoute>
        } />
      </Routes>
    </div>
  );
}

// Public Test Route component
const PublicTestRoute = ({ children }) => {
  const location = useLocation();
  
  // Check if we have the necessary state from the form submission
  const hasValidState = location.state && 
    location.state.registrationId && 
    location.state.email && 
    location.state.isExternalUser;

  if (!hasValidState) {
    // Redirect to the request form if accessed directly
    return <Navigate to="/cp-request" replace />;
  }

  return children;
};

export default App;