import { useState } from 'react';
import PermitService from '../services/PermitService';

const usePermits = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchPermits = async (type, status) => {
    setLoading(true);
    setError(null);
    try {
      const permits = await PermitService.fetchPermits(type, status);
      setLoading(false);
      return permits;
    } catch (error) {
      setError(error.message);
      setLoading(false);
      return [];
    }
  };

  const handleViewPermit = async (permitId) => {
    setLoading(true);
    setError(null);
    try {
      const permit = await PermitService.viewPermit(permitId);
      setLoading(false);
      return permit;
    } catch (error) {
      setError(error.message);
      setLoading(false);
      return null;
    }
  };

  const handleIssuePermit = async (permitId, permitData) => {
    setLoading(true);
    setError(null);
    try {
      const result = await PermitService.issuePermit(permitId, permitData);
      setLoading(false);
      return result;
    } catch (error) {
      setError(error.message);
      setLoading(false);
      return null;
    }
  };

  const handleClosePermit = async (closeData) => {
    setLoading(true);
    setError(null);
    try {
      console.log('usePermits - closeData received:', closeData);
      console.log('usePermits - closeDetails being sent:', closeData.closeDetails);
      const result = await PermitService.closePermit(closeData.permitId, closeData.closeDetails);
      setLoading(false);
      return result;
    } catch (error) {
      setError(error.message);
      setLoading(false);
      return null;
    }
  };

  // Add clearance handler
  const handleClearancePermit = async (clearanceData) => {
    console.log('usePermits - handleClearancePermit called with:', clearanceData);
    setLoading(true);
    setError(null);
    try {
      const result = await PermitService.clearPermit(clearanceData.permitId, clearanceData.clearanceDetails);
      console.log('usePermits - clearPermit result:', result);
      setLoading(false);
      return result;
    } catch (error) {
      console.error('usePermits - Error in handleClearancePermit:', error);
      setError(error.message);
      setLoading(false);
      return null;
    }
  };

  // Add control engineer consent handler
  const handleControlEngineerConsent = async (consentData) => {
    console.log('usePermits - handleControlEngineerConsent called with:', consentData);
    setLoading(true);
    setError(null);
    try {
      const result = await PermitService.addControlEngineerConsent(consentData.id, consentData.consentDetails);
      console.log('usePermits - addControlEngineerConsent result:', result);
      setLoading(false);
      return result;
    } catch (error) {
      console.error('usePermits - Error in handleControlEngineerConsent:', error);
      setError(error.message);
      setLoading(false);
      return null;
    }
  };

  return {
    loading,
    error,
    fetchPermits,
    handleViewPermit,
    handleIssuePermit,
    handleClosePermit,
    handleClearancePermit,
    handleControlEngineerConsent
  };
};

export default usePermits;