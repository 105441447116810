import React from 'react';
import { Tab } from '@headlessui/react';
import GcApprovalRequest from './GcApprovalRequest';
import QaQcApprovalRequest from './QaQcApprovalRequest';
import SapForm from './SapForm';
import EnergisationForm from './EnergisationForm';
import { AlertCircle } from 'lucide-react';

const ExpandedRowForms = ({ request, updateRequestStatus }) => {
  // Helper function to check if a step is completed
  const isStepCompleted = (stepName) => {
    switch (stepName) {
      case 'gc':
        return !!request.gccxApproval;
      case 'qaqc':
        return !!request.qaqcApproval;
      case 'sap':
        return !!request.sapApproval;
      case 'energisation':
        return !!request.energisationApproval;
      default:
        return false;
    }
  };

  // Helper function to check if a step is available
  const isStepAvailable = (stepName) => {
    switch (stepName) {
      case 'gc':
        return true; // GC is always available as first step
      case 'qaqc':
        return isStepCompleted('gc');
      case 'sap':
        return isStepCompleted('gc') && isStepCompleted('qaqc');
      case 'energisation':
        return isStepCompleted('gc') && isStepCompleted('qaqc') && isStepCompleted('sap');
      default:
        return false;
    }
  };

  // Helper function to get step status message
  const getStepMessage = (stepName) => {
    if (isStepCompleted(stepName)) return null;
    
    switch (stepName) {
      case 'qaqc':
        return !isStepCompleted('gc') ? 'Waiting for GC Approval' : null;
      case 'sap':
        return !isStepCompleted('qaqc') ? 'Waiting for QA/QC Approval' : null;
      case 'energisation':
        return !isStepCompleted('sap') ? 'Waiting for SAP Approval' : null;
      default:
        return null;
    }
  };

  const tabs = [
    { 
      id: 'gc',
      label: 'GC Approval',
      component: GcApprovalRequest,
      disabled: request.status === 'Completed',
      completed: isStepCompleted('gc')
    },
    { 
      id: 'qaqc',
      label: 'QA/QC Approval',
      component: QaQcApprovalRequest,
      disabled: !isStepAvailable('qaqc'),
      completed: isStepCompleted('qaqc')
    },
    { 
      id: 'sap',
      label: 'SAP Approval',
      component: SapForm,
      disabled: !isStepAvailable('sap'),
      completed: isStepCompleted('sap')
    },
    { 
      id: 'energisation',
      label: 'Energisation',
      component: EnergisationForm,
      disabled: !isStepAvailable('energisation'),
      completed: isStepCompleted('energisation')
    },
  ];

  return (
    <div className="relative w-full max-w-3xl mx-auto bg-gray-800 text-white p-6 rounded-lg shadow-lg">
      <Tab.Group>
        <Tab.List className="flex space-x-1 rounded-xl bg-gray-700 p-1">
          {tabs.map((tab) => (
            <Tab
              key={tab.id}
              disabled={tab.disabled}
              className={({ selected }) =>
                `w-full rounded-lg py-2.5 text-sm font-medium leading-5 
                ${selected
                    ? 'bg-blue-600 text-white shadow'
                    : 'text-gray-400 hover:bg-gray-600 hover:text-white'
                }
                ${tab.disabled 
                    ? 'opacity-50 cursor-not-allowed' 
                    : 'cursor-pointer'
                }
                ${tab.completed && !selected
                    ? 'text-green-400'
                    : ''
                }`
              }
            >
              <div className="flex items-center justify-center space-x-2">
                <span>{tab.label}</span>
                {tab.completed && (
                  <div className="w-2 h-2 rounded-full bg-green-500"></div>
                )}
              </div>
            </Tab>
          ))}
        </Tab.List>
        <Tab.Panels className="mt-4">
          {tabs.map((tab) => (
            <Tab.Panel
              key={tab.id}
              className="rounded-xl bg-gray-750 p-4 overflow-visible"
            >
              <div className="relative overflow-visible">
                {tab.disabled ? (
                  <div className="flex items-center space-x-2 text-yellow-400 p-4 overflow-visible">
                    <AlertCircle size={20} />
                    <span>{getStepMessage(tab.id)}</span>
                  </div>
                ) : (
                  <tab.component 
                    request={request} 
                    updateRequestStatus={updateRequestStatus}
                  />
                )}
              </div>
            </Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
};

export default ExpandedRowForms;