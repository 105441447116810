import React, { useState, useEffect, useMemo } from 'react';
import { permitStatusHelper } from './utils/permitStatusHelper';
import PermitActions from './PermitActions';
import PermitService from './services/PermitService';
import Pagination from './utils/Pagination';

const ITEMS_PER_PAGE = 10;

const PermitList = ({ 
  permits, 
  handleViewPermit, 
  handleIssuePermit, 
  handleClosePermit,
  handleTransferPermit,
  handleClearancePermit,
  handleEditPermit,
  handleControlEngineerConsent // Add this prop
}) => {
  const { getStatusColor } = permitStatusHelper;
  const [userCache, setUserCache] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [userNames, setUserNames] = useState({});

  // Memoize pagination calculations
  const { totalPages, currentPermits } = useMemo(() => {
    const total = Math.ceil(permits.length / ITEMS_PER_PAGE);
    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    const current = permits.slice(startIndex, startIndex + ITEMS_PER_PAGE);
    return { totalPages: total, currentPermits: current };
  }, [permits, currentPage]);

  const fetchUserData = async (userId) => {
    if (!userId) return null;
    if (userCache[userId]) return userCache[userId];

    try {
      const userData = await PermitService.getUserData(userId);
      if (userData) {
        setUserCache(prev => ({
          ...prev,
          [userId]: userData
        }));
        return userData;
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
    return null;
  };

  // Reset to first page when permits change
  useEffect(() => {
    setCurrentPage(1);
  }, [permits.length]);

  // Optimized user names fetching
  useEffect(() => {
    let isMounted = true;
    const loadUserNames = async () => {
      const names = {};
      const uniqueUserIds = new Set();

      currentPermits.forEach(permit => {
        if (permit.status === 'Prepared' && permit.preparedBy?.userId) {
          uniqueUserIds.add(permit.preparedBy.userId);
        } else if (permit.issuedDetails?.issuedBy?.userId) {
          uniqueUserIds.add(permit.issuedDetails.issuedBy.userId);
        }
      });

      for (const userId of uniqueUserIds) {
        if (!userNames[userId]) {
          const userData = await fetchUserData(userId);
          if (isMounted && userData) {
            names[userId] = userData.name;
          }
        }
      }

      if (isMounted) {
        setUserNames(prev => ({ ...prev, ...names }));
      }
    };

    loadUserNames();
    return () => {
      isMounted = false;
    };
  }, [currentPermits]);

  if (!permits || permits.length === 0) {
    return <div className="text-gray-400 text-center py-8">No permits found</div>;
  }

  const isPreparedStatus = permits[0]?.status === 'Prepared';

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="flex flex-col min-h-0">
      <div className="overflow-x-auto">
        <table className="w-full border-collapse">
          <thead>
            <tr className="bg-gray-800">
              <th className="border border-gray-700 p-2">Number</th>
              <th className="border border-gray-700 p-2">
                {isPreparedStatus ? 'Prepared By' : 'Issued By'}
              </th>
              <th className="border border-gray-700 p-2">
                {isPreparedStatus ? 'Requested By' : 'Current Holder'}
              </th>
              <th className="border border-gray-700 p-2">Location</th>
              <th className="border border-gray-700 p-2">Work to be Done</th>
              <th className="border border-gray-700 p-2">
                {isPreparedStatus ? 'Date Prepared' : 'Date Issued'}
              </th>
              <th className="border border-gray-700 p-2">Status</th>
              <th className="border border-gray-700 p-2">Actions</th>
            </tr>
          </thead>
          <tbody>
            {currentPermits.map((permit) => (
              <tr key={permit.id} className="hover:bg-gray-800 transition-colors duration-200">
                <td className="border border-gray-700 p-2">{permit.number}</td>
                <td className="border border-gray-700 p-2">
                  {permit.status === 'Prepared' 
                    ? userNames[permit.preparedBy?.userId] || 'Loading...'
                    : userNames[permit.issuedDetails?.issuedBy?.userId] || 'Loading...'}
                </td>
                <td className="border border-gray-700 p-2">
                  {permit.status === 'Prepared' ? (
                    <div>
                      <div>{permit.requestInfo?.fullName || 'N/A'}</div>
                      <div className="text-sm text-gray-400">{permit.requestInfo?.company || 'N/A'}</div>
                      <div className="text-sm text-gray-400">{permit.requestInfo?.telephone || ''}</div>
                    </div>
                  ) : (
                    permit.issuedDetails?.issuedTo ? (
                      <div>
                        <div>{permit.issuedDetails.issuedTo.name}</div>
                        <div className="text-sm text-gray-400">{permit.issuedDetails.issuedTo.company}</div>
                      </div>
                    ) : (
                      'Not Issued'
                    )
                  )}
                </td>
                <td className="border border-gray-700 p-2">{permit.location}</td>
                <td className="border border-gray-700 p-2">{permit.workToBeDone}</td>
                <td className="border border-gray-700 p-2">
                  {permit.status === 'Prepared' 
                    ? permit.createdAt?.toDate().toLocaleString()
                    : permit.issuedDetails?.issuedBy?.timestamp?.toDate().toLocaleString() || 'N/A'}
                </td>
                <td className="border border-gray-700 p-2">
                  <span className={`px-2 py-1 rounded ${getStatusColor(permit.status)}`}>
                    {permit.status}
                  </span>
                </td>
                <td className="border border-gray-700 p-2">
                  <PermitActions
                    permit={permit}
                    handleViewPermit={handleViewPermit}
                    handleIssuePermit={handleIssuePermit}
                    handleClosePermit={handleClosePermit}
                    handleTransferPermit={handleTransferPermit}
                    handleClearancePermit={handleClearancePermit}
                    handleEditPermit={handleEditPermit}
                    handleControlEngineerConsent={handleControlEngineerConsent} // Pass the prop to PermitActions
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      
      {totalPages > 1 && (
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      )}
    </div>
  );
};

export default PermitList;