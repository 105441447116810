import jsPDF from 'jspdf';
import 'jspdf-autotable';
import logo from '../components/assets/logo-black.png';

// Helper function to format dates consistently
const formatDate = (dateInput) => {
  if (!dateInput) return '';
  
  try {
    let date;
    
    // Handle Firestore Timestamp
    if (dateInput.seconds) {
      date = new Date(dateInput.seconds * 1000);
    }
    // Handle string input
    else if (typeof dateInput === 'string') {
      date = new Date(dateInput);
    }
    // Handle Date object
    else if (dateInput instanceof Date) {
      date = dateInput;
    }
    else {
      return '';
    }
    
    // Format as DD-MM-YYYY
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    
    return `${day}-${month}-${year}`;
  } catch (e) {
    console.error('Error formatting date:', e);
    return '';
  }
};

export const generatePDF = async (request) => {
  const doc = new jsPDF();
  const pageWidth = doc.internal.pageSize.width;
  const headerColor = [194, 172, 112];
  const tableWidth = pageWidth - 30;

  // Header background - light gold/brown box
  doc.setFillColor(...headerColor);
  doc.rect(0, 0, pageWidth, 30, 'F');

  // Logo in the left corner
  doc.addImage(logo, 'PNG', 15, 10, 43.5, 10);
  
  // Add title
  doc.setFontSize(20);
  doc.setTextColor(0);
  doc.setFont('helvetica', 'bold');
  doc.text('Energisation', pageWidth/2 - 25, 15);
  doc.text('Request', pageWidth/2 - 15, 25);

  // Date box (white background)
  doc.setDrawColor(0);
  doc.setFillColor(255, 255, 255);
  doc.rect(pageWidth - 70, 5, 60, 15, 'FD');
  doc.setFontSize(10);
  doc.text('Date of Request:', pageWidth - 65, 12);
  doc.text(formatDate(new Date()), pageWidth - 65, 17);

  // Main information table
  doc.autoTable({
    startY: 35,
    head: [[
      { content: 'Section 1: ENERGISATION REQUEST\nINFORMATION', colSpan: 3, styles: { fillColor: headerColor } },
      { content: `Energisation Type:\n${request.energisationType || ''}`, styles: { fillColor: headerColor } }
    ]],
    body: [
      [
        { content: 'Requestor:', styles: { fontStyle: 'bold' } },
        request.name || 'Teddy2',
        { content: 'Tag Review', colSpan: 2 }
      ],
      [
        { content: 'Company:', styles: { fontStyle: 'bold' } },
        request.company || 'SAST',
        { content: 'Yellow Tag\nComplete:', styles: { fontStyle: 'bold' } },
        request.gccxApproval?.yellowTag || ''
      ],
      [
        { content: 'Equipment:', styles: { fontStyle: 'bold' } },
        request.assetToEnergise || '',
        { content: 'Vendor Approval:', styles: { fontStyle: 'bold' } },
        request.gccxApproval?.yellowTag || ''
      ],
      [
        { content: 'Source:', styles: { fontStyle: 'bold' } },
        request.sourceAssetTag || '',
        { content: 'Issues:', styles: { fontStyle: 'bold' } },
        request.gccxApproval?.bimIssues || ''
      ],
      [
        { content: 'Location:', styles: { fontStyle: 'bold' } },
        request.location || 'Amsterdam',
        { content: '', colSpan: 2 }
      ],
      [
        { content: 'Voltage:', styles: { fontStyle: 'bold' } },
        request.voltageRange || '',
        { content: 'Requested\nPower on Date:', styles: { fontStyle: 'bold' } },
        formatDate(request.requestedPowerOnDate)
      ]
    ],
    theme: 'grid',
    styles: {
      fontSize: 9,
      cellPadding: 3,
      lineColor: [0, 0, 0],
      lineWidth: 0.1,
    },
    headStyles: {
      fillColor: headerColor,
      textColor: 0,
      fontStyle: 'bold',
      fontSize: 10,
      cellPadding: 3,
    },
    columnStyles: {
      0: { cellWidth: tableWidth * 0.2 },
      1: { cellWidth: tableWidth * 0.3 },
      2: { cellWidth: tableWidth * 0.25 },
      3: { cellWidth: tableWidth * 0.25 }
    },
    margin: { left: 15, right: 15 },
  });

  // Conditions section
  let currentY = doc.autoTable.previous.finalY + 5;
  doc.setFontSize(11);
  doc.setFont('helvetica', 'bold');
  doc.text('Conditions for Energisation:', 10, currentY);
  
  const conditions = [
    'The installation must be ready and safe to energise - observation MUST be unrelated to safe operation.',
    'All relevant installation and test documentation must be completed, uploaded, reviewed and signed off by the Cx Manager.',
    'The parties below are in agreement that energisation can occur.'
  ];

  doc.setFont('helvetica', 'normal');
  doc.setFontSize(9);
  conditions.forEach((condition, index) => {
    currentY += 8;
    doc.text(`${index + 1}.`, 10, currentY);
    doc.text(condition, 15, currentY, {
      maxWidth: pageWidth - 40,
    });
  });

  // Approval sections
  const sections = [
    {
      title: 'Section 2: GC Cx APPROVAL',
      content: 'Having reviewed/signed the equipment yellow tag I confirm the equipment/source detailed in section 1 is installation complete with all relevant documentation complete, uploaded, reviewed and accepted as safe to energise.',
      nameValue: request.gccxApproval?.name || '',
      dateValue: formatDate(request.gccxApproval?.date),
      signatureValue: request.gccxApproval?.signature || ''
    },
    {
      title: 'Section 3: QA/QC APPROVAL',
      content: 'Having Reviewed the equipment Yellow Tag I can confirm the equipment / source detailed in Section 1 can be energised and applied safety measures are appropriate. I authorise the energisation of the equipment detailed in Section 1.',
      nameValue: request.qaqcApproval?.name || '',
      dateValue: formatDate(request.qaqcApproval?.date),
      signatureValue: request.qaqcApproval?.signature || ''
    },
    {
      title: 'Section 4: SAP APPROVAL',
      content: 'I confirm the equipment/source detailed in section 1 has satisfactory test documentation for energisation. All locks have been applied to ensure safe control of the electrical installation following energisation.',
      nameValue: request.sapApproval?.name || '',
      dateValue: formatDate(request.sapApproval?.dateTime),
      signatureValue: request.sapApproval?.signature || ''
    },
    {
      title: 'Section 5: ENERGISATION DECLARATION',
      content: 'I confirm ALL above signatures are applied. Upon the basis I have visually inspected the equipment/source detailed in section 1 and energised. I have performed appropriate tests to verify voltage and confirm energisation.',
      nameValue: request.energisationApproval?.name || '',
      dateValue: formatDate(request.energisationApproval?.dateTime),
      signatureValue: request.energisationApproval?.signature || ''
    }
  ];

  currentY += 15;

  sections.forEach((section) => {
    if (currentY > doc.internal.pageSize.height - 40) {
      doc.addPage();
      currentY = 20;
    }

    // Section header and content
    doc.autoTable({
      startY: currentY,
      head: [[{ content: section.title, styles: { fillColor: headerColor } }]],
      body: [[{ content: section.content }]],
      styles: {
        fontSize: 9,
        cellPadding: 4,
        lineColor: [0, 0, 0],
        lineWidth: 0.1,
      },
      headStyles: {
        fillColor: headerColor,
        textColor: 0,
        fontStyle: 'bold',
        fontSize: 10,
        cellPadding: 4,
      },
      margin: { left: 15, right: 15 },
    });

    // Combined Name, Date and Signature row
    currentY = doc.autoTable.previous.finalY;
    doc.autoTable({
      startY: currentY,
      body: [[
        { content: 'Name:', styles: { fontStyle: 'bold' } },
        { content: section.nameValue || '' },
        { content: 'Date:', styles: { fontStyle: 'bold' } },
        { content: section.dateValue || '' },
        { content: 'Signature:', styles: { fontStyle: 'bold' } },
        { content: '' }
      ]],
      rowStyles: {
        0: { minCellHeight: 40 }
      },
      styles: {
        fontSize: 9,
        cellPadding: 3,
        lineColor: [0, 0, 0],
        lineWidth: 0.1,
      },
      theme: 'grid',
      margin: { left: 15, right: 15 },
      columnStyles: {
        0: { cellWidth: 20 },
        1: { cellWidth: 35 },
        2: { cellWidth: 20 },
        3: { cellWidth: 35 },
        4: { cellWidth: 25 },
        5: { cellWidth: 45 }
      },
      didDrawCell: function(data) {
        if (data.column.index === 5 && section.signatureValue) {
          const cell = data.cell;
          if (section.signatureValue.startsWith('data:image')) {
            try {
              doc.addImage(
                section.signatureValue,
                'PNG',
                cell.x + 1,
                cell.y + 1,
                cell.width - 4,
                cell.height - 4
              );
            } catch (e) {
              console.error('Error adding signature:', e);
              doc.text(
                'Signature on file',
                cell.x + 2,
                cell.y + cell.height/2
              );
            }
          } else {
            doc.text(
              section.signatureValue,
              cell.x + 2,
              cell.y + cell.height/2
            );
          }
        }
      }
    });

    currentY = doc.autoTable.previous.finalY + 2;
  });

  return doc.output('blob');
};

export default generatePDF;