// src/components/Mimic/services/mimicService.js
import { 
  addDoc, 
  collection, 
  getDocs,
  query,
  orderBy, 
  limit
} from 'firebase/firestore';
import { db } from '../../../services/firebase';

// Collection name - we'll just use one collection
const MIMICS_COLLECTION = 'mimics';

// Convert Line objects to plain objects for Firestore
const serializeLines = (lines) => {
  return lines.map(line => ({
    id: line.id,
    type: line.type,
    points: line.points,
    color: line.color,
    thickness: line.thickness
  }));
};

// Save a mimic to the database - we'll always create a new document
export const saveMimicToDB = async (lines) => {
  try {
    // Convert Line objects to plain objects
    const serializedLines = serializeLines(lines);
    
    // Create data object for Firestore
    const mimicData = {
      name: `Mimic_${new Date().toISOString().split('T')[0]}`,
      lines: serializedLines,
      createdAt: new Date(),
      updatedAt: new Date()
    };
    
    // Create new mimic document
    const mimicsRef = collection(db, MIMICS_COLLECTION);
    const docRef = await addDoc(mimicsRef, mimicData);
    
    console.log("New mimic saved with ID:", docRef.id);
    return docRef.id;
  } catch (error) {
    console.error("Error saving mimic:", error);
    throw error;
  }
};

// Get the most recent mimic
export const getLatestMimic = async () => {
  try {
    const mimicsRef = collection(db, MIMICS_COLLECTION);
    const q = query(mimicsRef, orderBy('updatedAt', 'desc'), limit(1));
    const snapshot = await getDocs(q);
    
    if (snapshot.empty) {
      console.log("No saved mimics found");
      return null;
    }
    
    // Return the most recent mimic
    const doc = snapshot.docs[0];
    return {
      id: doc.id,
      ...doc.data()
    };
  } catch (error) {
    console.error("Error fetching latest mimic:", error);
    throw error;
  }
};